import actionTypes from './actionTypes';
import {Dispatch} from 'redux'
import calloutHelper from '../../helpers/CalloutHelpers';

export const runAsGuestUserStart = ():actionTypes => {
    return {
        type: 'RUN_AS_GUEST_USER_START'
    }
}
export const setCusip = (cusip: OtherAsset):actionTypes => {
    return {
        type: 'SET_CUSIP', 
        payload: { cusip }
    }
}

export const loginSuccess = (userId: string, assets: Array<OtherAsset>, queuedTransactions: outstandingTransactions):actionTypes => {
    return {
        type: 'LOGIN_SUCCESS', 
        payload: {userId, assets, queuedTransactions}
    }
}

export const updateAssets = (assets: Array<OtherAsset>):actionTypes => {
    return {
        type: 'UPDATE_ASSETS', 
        payload: {assets}
    }
}

export const registerUserSuccess = (userId: string):actionTypes => {
    return {
        type: 'REGISTER_USER_SUCCESS', 
        payload: {userId}
    }
}

export const registerUserStart = ():actionTypes => {
    return {
        type: 'REGISTER_USER_START'
    }
}

export const logout = ():actionTypes => {
    localStorage.removeItem('state');
    return {
        type: 'LOGOUT'
    }
}

export const checkAuthSessionTimeout = (expirationTime: number) => {
    return (dispatch: Dispatch<actionTypes>) => 
    {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime)
    }
}

export const sessionResume = (payload : reducerState):actionTypes => {
    return {
        type: 'SESSION_RESUME', 
        payload
    }
}

export const setNewAsset = (isNewAsset: boolean): actionTypes =>{
    return {
        type: 'NEW_ASSET',
        payload: { isNewAsset }
    }
}

export const resetCusipState = async (dispatch: Function, type:userTypes, resetFormData:boolean = true) =>{
    dispatch(resetCusip())
    
    if(resetFormData){
        dispatch(setPageLoading(true))
        try{
            await calloutHelper.updateFormData({})
        }catch(err){
            
        }
    }
    
    if(type === 'guest'){
        dispatch(setPageLoading(true))
        await calloutHelper.resetGuestCusip()
    }
    dispatch(setPageLoading(false))
}

const resetCusip = (): actionTypes =>{
    return {
        'type': 'RESET_CUSIP_STATE'
    }
}

export const setPageLoading = (isLoading: boolean): actionTypes =>{
    return {
        type: 'SET_PAGE_LOADING',
        payload: { isLoading }
    }
}

export const updateTransactions = (queuedTransactions: outstandingTransactions): actionTypes =>{
    return {
        type: 'UPDATE_TRANSACTIONS',
        payload: {queuedTransactions}
    }
}