import React, { useEffect } from 'react';
import { 
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonLabel, 
    IonInput,
    IonText
  } from '@ionic/react'; 
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import calloutHelper from '../helpers/CalloutHelpers';
import {EMAIL_VALIDATION_PATTERN, PHONE_VALIDATION_PATTERN} from '../helpers/utils';
import * as actions from '../store/actions/actions';

const Profile: React.FC<{userId: string, showLoadingSpinner: Function, setErrorMessage: Function}> = ({userId, showLoadingSpinner, setErrorMessage}) => {
    const history = useHistory(); 
    const { register, handleSubmit, setValue, errors, formState } = useForm({
      mode: 'onChange'
    });

    useEffect(() => {
      if (userId) {
        showLoadingSpinner(true);
        calloutHelper.getUserInfo(userId).then(result => {
          for (const key in result.data) {
            setValue(key, result.data[key]);
          }
          showLoadingSpinner(false);
        }).catch(err => {
          setErrorMessage('Error finding user. Redirecting to home');
          showLoadingSpinner(false);
          setTimeout(() => {
            history.push('/paymenthome');
          }, 3000);
        })
      }
    }, [userId, history, setValue]);

    const onSubmit = (data: any, event: any) => {
      event.preventDefault();
      showLoadingSpinner(true);
      calloutHelper.updateUserInfo(userId, data.phone?.replace(/[- )(]/g,''), data.email, data.name).then((result : any) => {
        showLoadingSpinner(false);
        history.push('/home');
      }).catch(err => {
        showLoadingSpinner(false);
        setErrorMessage(err.message ? err.message : JSON.stringify(err));
      })
    } 

    let profileForm = (
        <IonGrid>
          <form onSubmit={handleSubmit(onSubmit)}>
            <IonRow>
              <IonCol className="p-1" sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
                <IonRow>
                  <IonCol className="p-1 light-gr-bg">
                    <IonRow>
                      <IonCol className="pl-3 pr-3 pt-1 pb-3 gr-border white-bg">
                      <h3 className="ion-text-left">
                          Profile Details 
                      </h3>
                      <IonRow>
                          <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
                            <IonRow className="mt-2 mb-1">
                              <IonLabel>Name *</IonLabel>
                            </IonRow>
                            <IonRow>
                              <IonInput className="ion-text-left gr-border" name="name" ref={register({
                                required: true, 
                                validate: val => (val && val.split(' ').length >= 2)
                              })} required={true} maxlength={100}></IonInput>
                            </IonRow>
                            <IonRow>
                              {errors.name && <IonText color='danger'> Please enter first and last name.</IonText>}
                            </IonRow>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
                            <IonRow className="mt-2 mb-1">
                              <IonLabel>E-mail *</IonLabel>
                            </IonRow>
                            <IonRow>
                              <IonInput className="ion-text-left gr-border" name="email" type="email"
                              ref={register({
                                required: true, 
                                pattern: new RegExp(EMAIL_VALIDATION_PATTERN)
                                })} required={true} maxlength={80}></IonInput>
                            </IonRow>
                            <IonRow>
                              {errors.email && <IonText color='danger'> Please enter valid email.</IonText>}
                            </IonRow>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
                            <IonRow className="mt-2 mb-1">
                              <IonLabel>Phone *</IonLabel>
                            </IonRow>
                            <IonRow>
                              <IonInput className="ion-text-left gr-border" name="phone" type="tel" 
                              ref={register({
                                required: true, 
                                pattern: new RegExp(PHONE_VALIDATION_PATTERN)
                              })} required={true}></IonInput>
                            </IonRow>
                            <IonRow>
                            {errors.phone && <IonText color='danger'> Please enter valid Phone.</IonText>}
                            </IonRow>
                          </IonCol>
                        </IonRow>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol class="mb-5">
                    <IonButton className="ion-float-right"size="large" type='submit' disabled={formState.isSubmitting}>Update Profile</IonButton>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </form>
        </IonGrid>
    )

    if (!userId) {
      profileForm = (
        <Redirect to="/"/>
      )
    }

    return (
      <div className="container">
        {profileForm}
      </div> 
    )
}

const mapStateToProps = (state: any) => {
  return {
    userId: state.userId
  }
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    showLoadingSpinner : (isPageLoading: boolean) => dispatch(actions.setPageLoading(isPageLoading))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);