import { IonContent, IonPage, IonLoading, IonToast } from '@ionic/react';
import React, {useEffect, useState} from 'react';
import { Redirect, useParams } from 'react-router';
import Header from '../components/Header';
import HomeContent from '../components/HomeContent';
import TypeOfPayment from '../components/TypeOfPayment';
import RentPayment from '../components/RentPayment';
import NotePayment from '../components/NotePayment';
import Results from '../components/Results';
import PaymentHome from '../components/PaymentHome';
import PaymentHistory from '../components/PaymentHistory';
import PaymentDetails from '../components/PaymentDetails';
import ReviewPayment from '../components/ReviewPayment';
import OTPVerify from '../components/OTPVerify';
import JWTVerify from '../components/JWTVerify';
import TimeoutModal from '../components/TimeoutModal';
import * as actions from '../store/actions/actions';
import {blankCusip} from '../store/reducers/reducer'
import { connect } from 'react-redux';
import Profile from '../components/Profile';
import PaymentSuccess from '../components/PaymentSuccess';
import RegisterForm from '../components/RegisterForm';
import calloutHelper from '../helpers/CalloutHelpers';
import Footer from '../components/Footer';
import ReactGA from 'react-ga';

const Main: React.FC<{isAuthenticated: boolean, userType: string, onSessionResume: Function, cusip: OtherAsset, showSpinner: boolean, assets: OtherAsset[] | undefined}> = ({isAuthenticated, userType, onSessionResume, cusip, showSpinner, assets}) => {
  const { comp } = useParams<{ comp: string; }>();
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [timeoutTime, setTimeoutTime] = useState<number | undefined>(); 
  const [showTimeoutModal, setShowTimeoutModal] = useState<boolean>(false);

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'production' && process.env.REACT_APP_GA_TRACKING){
     ReactGA.pageview(comp, [], comp); 
    }
  }, [comp])

  useEffect(() => {
      calloutHelper.getSessionOnStart().then(result => {
        let { data } = result;
        if(!data){
          return
        }
        if (data.user !== undefined && Object.keys(data.user).length === 0) {
          return;
        }
        let payload: Partial<reducerState> = {
          userType: data?.user?.type ? data.user.type : 'guest', 
          cusip: (data.user.cusip && data?.user?.cusip?.id) ? data.user.cusip : blankCusip, 
          userId: (data?.user?.Id) ? data.user.Id : '',
          assets: data?.assets,
          queuedTransactions: data?.queuedTransactions
        };

        let expirationTime = Date.parse(data.expirationTime);
        let timeout: number = expirationTime - Date.now(); 
        setTimeoutTime(timeout);

        onSessionResume(payload);
      }).catch(err => {
        setErrorMessage('An error has occured');
      })
  }, [onSessionResume]);

  useEffect(() => {
    let timeoutId : any;
    if (timeoutTime) {
      let showTimeoutModalTime = timeoutTime - 10000;
      timeoutId = setTimeout(() => {
        setShowTimeoutModal(true);
      }, showTimeoutModalTime);
    }

    return () => { 
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    }

  }, [timeoutTime]);

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage('');
      }, 5000);
    }
  }, [errorMessage]); 

  const landingPageToDisplay = () => {
    if (!isAuthenticated) {
      return <HomeContent setPhone={setPhone} setEmail={setEmail} setErrorMessage={setErrorMessage}/>
    }
    if (userType === 'guest' && cusip.id) {
      return <TypeOfPayment/>
    }
    if (userType === 'guest' && assets && assets.length > 0) {
      return <HomeContent setPhone={setPhone} setEmail={setEmail} setErrorMessage={setErrorMessage}/>;
    }
    return <PaymentHome />
  }

  const displayPage = (pageName: string) => {
    switch (pageName) {
      case 'home':
        return landingPageToDisplay();
      case 'verifylogin':
        return <OTPVerify phone={phone} email={email} setTimeoutTime={setTimeoutTime} verifyType={'login'}/>
      case 'verifyregister':
        return <OTPVerify phone={phone} email={email} setTimeoutTime={setTimeoutTime} verifyType={'register'}/>
      case 'typeofpayment':
        return <TypeOfPayment />
      case 'rentpayment':
        return <RentPayment setTimeoutTime={setTimeoutTime} />
      case 'notepayment':
        return <NotePayment setTimeoutTime={setTimeoutTime} />
      case 'results':
        return <Results />
      case 'paymentdetails':
        return (isAuthenticated ? <PaymentDetails /> : <Redirect to="/"/>);
      case 'reviewpayment':
        return (isAuthenticated ? <ReviewPayment /> : <Redirect to="/"/>);
      case 'paymenthome':
        return ((isAuthenticated && userType !== 'guest') ? <PaymentHome/> : <Redirect to="/"/>);
      case 'paymenthistory':
        return ((isAuthenticated && userType !== 'guest')  ? <PaymentHistory /> : <Redirect to="/"/>);
      case 'paymentsuccess':
        return (isAuthenticated ? <PaymentSuccess setTimeoutTime={setTimeoutTime} setErrorMessage={setErrorMessage} /> : <Redirect to="/" />)
      case 'myprofile':
        return ((isAuthenticated && userType !== 'guest') ? <Profile setErrorMessage={setErrorMessage} /> : <Redirect to="/"/>);
      case 'register':
        return ((userType === 'registering' || (isAuthenticated && userType === 'guest')) ? <RegisterForm setPhone={setPhone} setEmail={setEmail} setErrorMessage={setErrorMessage} setTimeoutTime={setTimeoutTime} /> : <Redirect to="/" />);
      default:
        return <JWTVerify jwtToken={comp} setTimeoutTime={setTimeoutTime}/>;
    }
  }
  return (
    <IonPage>
      <TimeoutModal showTimeoutModal={showTimeoutModal} setTimeoutTime={setTimeoutTime} setShowTimeoutModal={setShowTimeoutModal} />
      <IonLoading isOpen={showSpinner} message={'Loading..'} spinner="lines" duration={20000}/>
      <IonToast isOpen={errorMessage !== ''} message={errorMessage} onDidDismiss={() => setErrorMessage('')} color="danger" position="top"></IonToast>
      <Header setTimeoutTime={setTimeoutTime}/>
      
      <IonContent>
      <div className={'main-page'}>
        {displayPage(comp?.toLowerCase())}
        </div>
        <Footer/>
      </IonContent>
      
    </IonPage>    
  );
};


const mapStateToProps = (state: reducerState) => {
  return {
    isAuthenticated: ((state.userType === 'guest' && (state.cusip.id !== '' || state.assets !== undefined)) || (state.userId !== '')),
    userType: state.userType,
    cusip: state.cusip,
    showSpinner: state.pageLoading,
    assets: state.assets
  }
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    onSessionResume: (data: reducerState) => dispatch(actions.sessionResume(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
