export const loadState = () => {
    try {
        const serializedState = sessionStorage.getItem('state');
        if (!serializedState){ return undefined;}
        let sessionState: reducerState = JSON.parse(serializedState)
        sessionState.pageLoading = false
        return sessionState
    } catch (err){
        return undefined; 
    }
}

export const saveState = (state: any) => {
    try {
        const serializedState  = JSON.stringify(state); 
        sessionStorage.setItem('state', serializedState); 
    } catch (err) { 
        console.log(err);
    }
}