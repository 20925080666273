import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import '../pages/Style.css';
import progressBarStepTwo from '../images/step-two.svg';
import { 
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonLabel,
  IonItem,
} from '@ionic/react';
import AlternateLookup from '../components/AlternateLookup';
import AutoSuggest from 'react-autosuggest';
import '../pages/Style.css';
import calloutHelper from '../helpers/CalloutHelpers';
import { useHistory } from 'react-router';
import * as actions from '../store/actions/actions';
import { connect } from 'react-redux';
import { GENERIC_RE_CUSIP_TEXT, AssetLookupContainer } from '../helpers/utils';

const AutoSuggestRealEstate = AutoSuggest as { new (): AutoSuggest<RealEstateAsset> };


interface PropertyLookupContainer extends AssetLookupContainer {
  propertyId?: string,
  cusip?: OtherAsset
}

const RentPayment: React.FC<PropertyLookupContainer> = ({propertyId, userType, setCusip, cusip, resetCusip, setNewAsset, setTimeoutTime}) => { 
  let history = useHistory();
  const setNewAssetTrueAndTransition = ()=>{
    setNewAsset();
    history.push('/paymentdetails')
  }
  const { register, handleSubmit, setValue } = useForm({
     mode: 'onChange',
  }),
    [showError, setShowError] = useState(false),
    [showValError, setShowValError] = useState(false),
    [showAlternateLookup, setShowAlternateLookup] = useState(false),
    [suggestions, setSuggestions] = useState<RealEstateAsset[]>([]),
    [propertyInputValue, setPropertyInputValue] = useState('');

  const onSubmit = (data: any) => {
    if ((showAlternateLookup && !data.altlookup)) {
      showTimedValidationError();
      return;
    }

    if(!propertyId) {
      showTimedError();
      return;
    }

    setShowValError(false);

    if (userType !== 'guest') {
      setNewAssetTrueAndTransition();
      return;
    }
    if(cusip){
      calloutHelper.startGuestUserSession(cusip, 'property').then(result => {
        if (result.data.expirationTime){
          let expirationTime = Date.parse(result.data.expirationTime);
          let timeout: number = expirationTime - Date.now(); 
          setTimeoutTime(timeout);
        }
        setNewAssetTrueAndTransition();
      })
    }
  }

  const showTimedError = useCallback(() => {
    setShowError(true);
    setShowAlternateLookup(true);
      setTimeout(() => {
        setShowError(false);
      }, 3000);
  },[]);

  const showTimedValidationError = useCallback(() => {
    setShowValError(true);
    setTimeout(() => {
      setShowValError(false);
    }, 3000);
  }, []);


  
  const handlePropertyInputChange = (event: any, {newValue, method} : any) => {
    if (method === 'type') {
      if(cusip?.id !== ''){
        resetCusip(userType);
      }
      setValue('altlookup', '');
      setShowAlternateLookup(false); 
    }
    setPropertyInputValue(newValue);
  }

  const getSuggestions = async (value :string)=> {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    
    if (inputLength < 3) {
      return [];
    };

    try {
      let queryResult = await calloutHelper.findProperty(inputValue);
      if (queryResult?.data?.data) {
        return queryResult.data.data; 
      }
      return [];
    }
    catch(err) {
      return [];
    }

  }

  const onSuggestionsFetchRequested = ({value} : any) => {
    getSuggestions(value).then(propertyResults => {
      if (!propertyResults) propertyResults = [];
      setSuggestions(propertyResults);
    }).catch(err => {
      setSuggestions([]);
    });
  }

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  }

  const getSuggestionValue = (suggestion: RealEstateAsset) => {
    setCusip({
      id: suggestion.id,
      asset_description: suggestion.address,
      name: suggestion.name,
      type: 'real-estate',
      isGeneric: false
    });
    return suggestion.address
  };

  const renderSuggestion = (suggestion: RealEstateAsset) => (
    <div key={suggestion.id}>
      {suggestion.address}
    </div>
  );

  const onAltLookupChange = (event: CustomEvent) => {
    if (event.detail.value && event.detail.value.trim() !== '') {
      let cusipId = GENERIC_RE_CUSIP_TEXT
      setCusip({id:cusipId, asset_description: event.detail.value, name: 'Generic RE Cusip', type:'real-estate', isGeneric: true });
    }
  }

  return (
    <div className="container">
      <IonGrid>
        <IonRow>
        <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
            <IonRow>
              <IonCol class="p-1 light-gr-bg">
                <IonRow>
                  <IonCol class="pl-3 pr-3 pt-1 pb-3 gr-border white-bg">
                    <IonRow class="divider">
                      <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                        <img src={progressBarStepTwo} alt="progress bar" />
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        <h2 className="ion-text-left">Please search the property you would like to pay rent towards.</h2>
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <IonRow>
                            <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
                                <IonRow class="mt-2 mb-1">
                                  <IonLabel>Address</IonLabel>
                                </IonRow>
                                <IonRow>
                                    <AutoSuggestRealEstate 
                                    suggestions={suggestions} 
                                    onSuggestionsFetchRequested={onSuggestionsFetchRequested} 
                                    onSuggestionsClearRequested={onSuggestionsClearRequested} 
                                    getSuggestionValue={getSuggestionValue} 
                                    renderSuggestion={renderSuggestion} 
                                    inputProps={{
                                      placeholder: 'Property', 
                                      value: propertyInputValue,
                                      onChange: handlePropertyInputChange,
                                      required: true
                                    }} ref={register}
                                    multiSection={false}
                                    />
                                </IonRow>
                                {showError && <IonItem class="mt-1" color="danger"><p className="white-color">Property not found. Please enter the address for the property which you wish to make a rent payment towards.</p></IonItem>}
                                {showValError && <IonItem class="mt-1" color="danger"><p className="white-color">Please enter a valid property address.</p></IonItem>}
                                  {showAlternateLookup && <AlternateLookup register={register} onChange={onAltLookupChange}/>}
                                <IonButton class="mt-2" color="primary" size="large" type="submit">SUBMIT</IonButton>
                              </IonCol>
                            </IonRow>
                          </form>
                        <IonRow class="mt-5 divider" />
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

const mapStateToProps = (state: reducerState) => {
  return {
    propertyId: state.cusip.id,
    cusip: state.cusip,
    userType: state.userType
  }
}


const mapDispatchToProps = (dispatch: Function) => {
  return{
    setCusip: (property: OtherAsset) => dispatch(actions.setCusip(property)),
    resetCusip: (userType: userTypes) => actions.resetCusipState(dispatch, userType, false),
    setNewAsset: () => dispatch(actions.setNewAsset(true))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RentPayment);
