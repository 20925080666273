import React, {useState} from 'react'
import { updateTransactions } from '../store/actions/actions'
import QueuedtransactionForm from './QueuedtransactionForm'
import calloutHelpers from '../helpers/CalloutHelpers'
import { AxiosResponse } from 'axios'
import { blankCusip } from '../store/reducers/reducer'

const EditTransaction: React.FC<{ setEditParams(params:editParams):any, editparams: editParams, updateTransactions(newQueuedTransactions: outstandingTransactions):any, setLoading(isLoading:boolean):any}> = ({editparams, setEditParams, updateTransactions, setLoading }) =>{
    const submitCallback = (formData:paymentDetailsForm) => {
        let updatedQueuedTransaction: Queued_Transaction__c = {...editparams.transaction,
            Amount_Cash__c: formData.payment_amount,
            Amount_Interest__c: formData.amount_interest,
            Amount_Principal__c: formData.amount_principal,
            Bank_Account_Number__c: formData.bank_account?.includes('*')? undefined: formData.bank_account,
            Bank_Name__c: formData.bank_name,
            BankAccountType__c: formData.bank_account_type,
            Routing_Number__c: formData.bank_routing,
            Process_Date__c: formData.process_date,
            EndDate__c: formData.end_date,
            Frequency__c: formData.frequency,
            Email__c: formData.email,
            Phone__c: formData.phone,
            Special_Notes__c: formData.notes,
        }
        setLoading(true)
        calloutHelpers.updateTransaction(updatedQueuedTransaction).then((result)=>{

            updateTransactions(result.data)
        }).finally(()=>{
            setLoading(false)
            setEditParams({
                cusip: {
                    id:'',
                    asset_description:'',
                    name: '',
                    type: '',
                    isGeneric: false
                },
                transaction: {},
                isEditing:false
            });
        })
    }

    let formData: paymentDetailsForm = {
        payment_amount: editparams.transaction.Amount_Cash__c,
        amount_interest: editparams.transaction.Amount_Interest__c,
        amount_principal: editparams.transaction.Amount_Principal__c,
        bank_account: '*********',
        bank_routing: editparams.transaction.Routing_Number__c,
        bank_account_type: editparams.transaction.BankAccountType__c,
        bank_name: editparams.transaction.Bank_Name__c,
        process_date: editparams.transaction.Process_Date__c,
        end_date: editparams.transaction.EndDate__c,
        frequency: editparams.transaction.Frequency__c,
        email: editparams.transaction.Email__c,
        phone: editparams.transaction.Phone__c,
        notes: editparams.transaction.Special_Notes__c
    }

    let nextAndBackParams: nextAndBackParams = {
        nextButtonText:'Save',
        backButtonText:'Cancel',
        backButtonCallback:(event)=>{
            setEditParams({
                isEditing:false,
                transaction:{},
                cusip: blankCusip
            })
        },
    }

    if( editparams.transaction.AssetDescriptionFromPayer__c ){
        editparams.cusip.asset_description = editparams.transaction.AssetDescriptionFromPayer__c
    }

    let hideOneTimeOption = editparams.transaction.Status__c === 'Recurring';

    return(<QueuedtransactionForm cusip={editparams.cusip} userType={'loggedIn'} submitCallback={submitCallback} formData={formData} hideDisclosure={false} nextAndBackParams={nextAndBackParams} hideOneTimeOption={hideOneTimeOption}/>)
}

export default EditTransaction