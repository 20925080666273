import React, {useState} from 'react';
import '../pages/Style.css';
import { 
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonModal,
  IonList,
  IonListHeader,
  IonItem
} from '@ionic/react'; 
import { connect } from 'react-redux';
import { updateTransactions, setPageLoading } from '../store/actions/actions'
import calloutHelper from '../helpers/CalloutHelpers';
import EditTransaction from './EditTransaction'

const PaymentHome: React.FC<{assets?: Array<OtherAsset>, queuedTransactions?: outstandingTransactions, updateTransactions(newTransactions:outstandingTransactions):any, setPageLoading(val:boolean):any }> = ({ assets, queuedTransactions, updateTransactions, setPageLoading }) => {
const [editParams, seteditParams] = useState<editParams>()

const [showCancelModal, setShowCancelModal] = useState(false);
const [transactionToCancel, setTransactionToCancel] = useState<Queued_Transaction__c>();

const userHasAssets = ()=>{
  if(assets){
    return assets.filter(value=>!value.isGeneric).length > 0
  }
  return false;
}

const editTransaction = (transaction: Queued_Transaction__c, cusip?: OtherAsset)=>{
  if(cusip){
    seteditParams({
      cusip,
      transaction,
      isEditing: true
    })
  }
  
}

const outstandingTransactionList = (transactionList?: Array<Queued_Transaction__c>) =>{

  return transactionList?.map((queuedTransaction)=>{
    return <React.Fragment key={queuedTransaction.Id}>
      <IonItem>
        <IonGrid tabIndex={0}>
          <IonRow>
            <IonCol>{queuedTransaction.CUSIP__r?.Asset_Description__c}</IonCol>
            <IonCol class={'ion-text-right'}>
              <IonCol><IonButton onClick={()=>editTransaction(queuedTransaction, assets?.find((cusip)=>{return (cusip.id===queuedTransaction.CUSIP__c)}))}>Edit</IonButton></IonCol>
              <IonCol><IonButton onClick={()=>confirmCancelTransaction(queuedTransaction)}>Cancel</IonButton></IonCol>
            </IonCol>
          </IonRow>
      </IonGrid>
      </IonItem>
    </React.Fragment>
  })
}

const outStandingTransactions = (queuedTransactions: outstandingTransactions|undefined) =>{
  let showOutstandingTrans = showOutstandingTransactions(queuedTransactions)
  let minColumnSize = showOutstandingTrans.showOneTime && showOutstandingTrans.showRecurring ? "6" : "12"
  return <IonRow>
    {showOutstandingTrans.showOneTime && <IonCol sizeXs="12" sizeSm="12" sizeMd={minColumnSize} sizeLg={minColumnSize} sizeXl={minColumnSize}>
      <IonList lines={'full'}>
        <IonListHeader lines='full' class={"ion-align-items-center"}><h4 className={"title"}>Pending One Time Transactions</h4></IonListHeader>
        {outstandingTransactionList(queuedTransactions?.oneTimeTransactions)}
      </IonList>
    </IonCol>}
    {showOutstandingTrans.showRecurring && <IonCol sizeXs="12" sizeSm="12" sizeMd={minColumnSize} sizeLg={minColumnSize} sizeXl={minColumnSize}>
      <IonList lines={'full'}>
        <IonListHeader lines='full' class={"ion-align-items-center"}><h4 className={"title"}>Active Recurring Transactions</h4></IonListHeader>
        {outstandingTransactionList(queuedTransactions?.recurringTransactions)}
      </IonList>
    </IonCol>}
    </IonRow>
   
}

const confirmCancelTransaction = (transaction?:Queued_Transaction__c) => {
  setTransactionToCancel(transaction)
  setShowCancelModal(true)
}

const doNotCancelTransaction = () =>{
  setTransactionToCancel(undefined);
  setShowCancelModal(false)
}

const cancelTransaction = () => {
  if(!transactionToCancel){
    return
  }
  let transaction = transactionToCancel
  setTransactionToCancel(undefined)
  setShowCancelModal(false)

  setPageLoading(true)
  calloutHelper.cancelTransaction(transaction).then((result)=>{
    updateTransactions(result.data);
  }).finally(()=>{
    setPageLoading(false)
  })
}

  if(!editParams?.isEditing){
  return (
    <div className="container">
      <IonGrid>
      <IonModal isOpen={showCancelModal} keyboardClose={true} animated={false}>
        <IonGrid>
          <IonRow class={'pt-1'}>
          <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
          <h4>Are you sure you want to cancel this transaction?</h4>
            </IonCol>
          </IonRow>
          <IonRow class={'pt-11'}>
            <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
              <IonButton onClick={() => cancelTransaction()}>Confirm</IonButton>
              <IonButton onClick={() => doNotCancelTransaction()}>Back</IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonModal>
        <IonRow>
          <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
            <IonRow>
              <IonCol class="p-1 light-gr-bg">
                <IonRow>
                  <IonCol class="pl-3 pr-3 pt-3 pb-3 gr-border white-bg ion-text-center">
                    {userHasAssets() && <IonButton color="secondary" routerLink="/PaymentDetails" size="large">New Payment</IonButton>}
                    {<IonButton color="secondary" routerLink="/TypeOfPayment" size="large">New Asset</IonButton>}
                  </IonCol>
                </IonRow>
                {outStandingTransactions(queuedTransactions)}
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );}else{
    return <EditTransaction updateTransactions={updateTransactions} editparams={editParams} setEditParams={seteditParams} setLoading={setPageLoading}/>
  }
};

const showOutstandingTransactions = (queuedTransactions?: outstandingTransactions) => {
  let oneTimeTransactions = queuedTransactions?.oneTimeTransactions
  let recurringTransactions = queuedTransactions?.recurringTransactions

  let showOneTime = false
  let showRecurring = false

  if(oneTimeTransactions && oneTimeTransactions.length > 0){
    showOneTime = true
  }

  if(recurringTransactions && recurringTransactions.length > 0){
    showRecurring = true
  }
  return {showOneTime,showRecurring}
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    updateTransactions: (newQueuedTransactions: outstandingTransactions) => {dispatch(updateTransactions(newQueuedTransactions))},
    setPageLoading: (spinnerOn: boolean) => {dispatch(setPageLoading(spinnerOn))}
  }
}

const mapStateToProps = (state: reducerState) => {
  return {
    assets: state.assets,
    queuedTransactions: state.queuedTransactions
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentHome);
