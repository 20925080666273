import actionTypes from '../actions/actionTypes';

export const blankCusip: OtherAsset = {
    id: '',
    asset_description: '',
    name: '',
    type: '',
    isGeneric: false
}

const initialState : reducerState = {
    userType: 'guest',
    cusip: blankCusip,
    newAsset: false,
    userId: '',
    assets: undefined,
    queuedTransactions: undefined,
    pageLoading: false
};

const reducer = (state = initialState, action: actionTypes) : reducerState => {
    switch (action.type){
        case 'RUN_AS_GUEST_USER_START': 
            return {
                ...state,
                userType: 'guest'
            }
        
        case 'LOGIN_SUCCESS':
            return {
                ...state, 
                userType: 'loggedIn',
                userId: action.payload.userId,
                assets: action.payload.assets,
                queuedTransactions: action.payload.queuedTransactions
            }
        case 'UPDATE_ASSETS':
            return{
                ...state,
                assets: action.payload.assets
            }
        case 'REGISTER_USER_START':
            return {
                ...state, 
                userType: 'registering'
            }
        case 'REGISTER_USER_SUCCESS':
            return {
                ...state, 
                userId: action.payload.userId
            }
        case 'SET_CUSIP':
            return {
                ...state, 
                cusip: action.payload.cusip
            }
        
        case 'RESET_CUSIP_STATE':
            return {
                ...state,
                cusip: blankCusip,
                newAsset: false
            }

        case 'NEW_ASSET':
            return {
                ...state,
                newAsset: action.payload.isNewAsset
            }

        case 'LOGOUT':
            return {...initialState};

        case 'SESSION_RESUME':
            return {
                ...state, 
                ...action.payload
            }
        
        case 'SET_PAGE_LOADING':
            return {
                ...state,
                pageLoading: action.payload.isLoading
            }

        case 'UPDATE_TRANSACTIONS':
            return {
                ...state,
                queuedTransactions: action.payload.queuedTransactions
            }


        default:
            return state; 
    }
}

export default reducer; 