import axios, { AxiosResponse } from 'axios';

let calloutHelper =  {
    getSessionOnStart : () => axios.get<{},AxiosResponse<sessionResume>>('/getSessionOnStart'),
    
    sendLoginRequest : (phone: string|null, email: string|null) => axios.post('/verifyUserAndSendAuthToken', {phone, email}),

    verifyOtpRequest : (verifyOtpBody: verifyOtpBody) => axios.post<verifyUser>('/verifyOtp', verifyOtpBody),

    verifyJWTRequest : (jwtToken: string | null) => axios.post<verifyUser>('/verifyJWT', {jwtToken}),

    resendOtp : (phone: string | null, email: string | null, verifyType: 'register' | 'login', userType: string ) => axios.post('/resendOtp', {phone, email, verifyType, userType}),

    startGuestUserSession : (cusip: OtherAsset, cusipType: CusipType) => axios.post('/startGuestUserSession', {cusip, cusipType}) ,

    findProperty : (propertyInput: string) => axios.post('/findProperty', {propertyInput}),

    findCusip: (cusipName: string) => axios.post<findCusipResponse>('/findCusip', {cusipName}),
    
    getUserInfo : (userId: string) => axios.post('/getUserInfo', {userId}),

    getFormData : () => axios.get<{},AxiosResponse<paymentDetailsForm>>('/getFormData'),

    updateFormData : (formData: paymentDetailsForm) => axios.post('/updateFormData', formData),

    resetGuestCusip : () => axios.post('/resetGuestCusip'),

    updateUserInfo: (userId: string, phone: string, email: string, name: string) => axios.post('/updateUserInfo', {userId, phone, email, name}),

    submitAsset: (submitBody: submitAssetBody)=> axios.post<SubmitAssetResponse>('/submitAsset', submitBody),

    cancelTransaction: (transaction: Queued_Transaction__c)=> axios.post<outstandingTransactions>('/cancelTransaction', transaction),

    sendRegistrationCodeToGuestUser: (name: string, email: string | null, phone: string | null, authenticationMethod: 'email' | 'phone' | null) => axios.post('/sendRegistrationCode', {name, email, phone, authenticationMethod}),

    sendRegistrationCodeToRegisteringUser: (name: string, email: string | null, phone: string | null, authenticationMethod: 'email' | 'phone' | null) => axios.post('/sendRegistrationCode', {name, email, phone, authenticationMethod, userType: 'registering'}), 

    getIsBankRouteValid: (routingNumber : string) => axios.post<validateRoutingNumber>('/validateBankRoute', {routingNumber}),

    getPaymentHistory: () => axios.get<any, AxiosResponse<assetDescriptionToTransactionList>>('/paymentHistory'),

    updateTransaction: (update: Queued_Transaction__c) => axios.post<outstandingTransactions>('/updateTransaction',update),

    continueSession: () => axios.post('/updateSessionExpiration'),

    signout: () => axios.post('/signout')
};

export default calloutHelper;
