import React from 'react';
import '../pages/Style.css';
import { 
  IonButtons,
  IonIcon,IonButton, isPlatform
} from '@ionic/react';
import { logOut } from 'ionicons/icons';
import * as actions from '../store/actions/actions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import calloutHelpers from '../helpers/CalloutHelpers';
import { TabToolbar, isMobile } from '../helpers/utils';

const PaymentTabs: React.FC<TabToolbar> = ({dispatchLogout, setTimeoutTime}) => {
  const history = useHistory();

  const logout= () => {
    calloutHelpers.signout().then(() => {
      logoutAndRedirect();
      setTimeoutTime(undefined);
    }).catch(err => {
      logoutAndRedirect();
      setTimeoutTime(undefined);
    })
  }

  const logoutAndRedirect = () => {
    dispatchLogout();
    history.push('/home');
  }

  return (
    <IonButtons className={isMobile(isPlatform) ? "" : "ion-float-right"}>
        <IonButton routerLink="/Paymenthome"> Home</IonButton>
        <IonButton routerLink="/PaymentHistory"> History</IonButton>
        <IonButton routerLink="/MyProfile"> Profile </IonButton>
        <IonButton size='small' fill='clear' color='secondary' onClick={logout}>
            <IonIcon size="small" icon={ logOut } color="secondary"></IonIcon>Log Out
        </IonButton>
  </IonButtons>
  );
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    dispatchLogout: () => dispatch(actions.logout())
  };
}

export default connect(null, mapDispatchToProps)(PaymentTabs);
