import React from 'react';
import ReactDOM from 'react-dom';
import {createStore, applyMiddleware, compose } from 'redux';
import { Provider} from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import reducer from './store/reducers/reducer';
import {loadState, saveState} from './store/sessionStorageHelper';
import thunk from 'redux-thunk';
import throttle from 'lodash.throttle';

const composeEnhancers =  compose;

const store = createStore(reducer, loadState(), composeEnhancers(applyMiddleware(thunk)));

store.subscribe(throttle(() => {
    saveState(store.getState());
}, 1000))

ReactDOM.render(
    <Provider store={store}>
         <App />
    </Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
