import React, { useEffect, useState } from 'react';
import { IonModal, IonButton, IonContent, IonTitle, IonGrid, IonRow } from '@ionic/react';
import {useHistory} from 'react-router-dom';
import calloutHelper from '../helpers/CalloutHelpers';
import { connect } from 'react-redux';
import * as actions from '../store/actions/actions';


const COUNT_DOWN = 10

const TimeOutModal: React.FC<{showTimeoutModal: boolean, setTimeoutTime: Function, setLoading: Function,  logoutOnTimeout: Function, setShowTimeoutModal: Function}> = ({showTimeoutModal, setTimeoutTime, setLoading, logoutOnTimeout, setShowTimeoutModal}) => {
    let history = useHistory();
    const [countDown, setCountDown] = useState(COUNT_DOWN);    
    
    useEffect(()=>{
        let countDownInterval: any;
        if(showTimeoutModal){
            var counter = countDown;
            countDownInterval = setInterval(()=>{
                if(counter === 0){
                    endSession(); 
                    return;                   
                }
                --counter;
                setCountDown(counter);
            },1000)
        }
        return () => {
            if (countDownInterval) {
                clearTimeout(countDownInterval);
            }
        }
    },[showTimeoutModal])

    const continueApp = () => {
        setLoading(true);
        calloutHelper.continueSession().then((result) => {
            let { data } = result;
            let expirationTime = Date.parse(data.expirationTime);
            let timeout: number = expirationTime - Date.now();
            setTimeoutTime(timeout);
        }).finally(() => {
            setLoading(false);
            setShowTimeoutModal(false);
        });
    }

    const endSession = () => {
        setLoading(true);
        calloutHelper.signout().then(() => {
            logoutOnTimeout();
        }).catch(err => {
            logoutOnTimeout();
        }).finally(() => {
            setLoading(false);
            setTimeoutTime(undefined);
            setShowTimeoutModal(false);
            history.push('/');
            return; 
        });
    }

    return (   
    <IonModal isOpen={showTimeoutModal} backdropDismiss={false}>
        <IonContent className='ion-padding'>
            <IonGrid>
                <IonRow>
                    <IonTitle>Session will end in {countDown} second(s)</IonTitle>
                </IonRow>
                <IonRow className='ion-justify-content-center'>
                    <IonButton onClick={continueApp}>Continue</IonButton>
                    <IonButton onClick={endSession}>Close Application</IonButton>
                </IonRow>
            </IonGrid>
        </IonContent>
    </IonModal>
  );
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        logoutOnTimeout: () => dispatch(actions.logout()),
        setLoading: (isLoading: boolean) => dispatch(actions.setPageLoading(isLoading))
    }
}

export default connect(null, mapDispatchToProps)(TimeOutModal); 