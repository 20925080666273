import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import '../pages/Style.css';
import { 
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonLabel,
  IonInput,
  IonItem,
  isPlatform
} from '@ionic/react';
import calloutHelper from '../helpers/CalloutHelpers';
import * as actions from '../store/actions/actions';
import { connect } from 'react-redux';
import {EMAIL_VALIDATION_PATTERN, PHONE_VALIDATION_PATTERN, isMobile} from '../helpers/utils';

const HomeContent: React.FC<{setPhone:Function, setEmail: Function, onRegisterUserStart: Function, onRunAsGuestUserStart: Function, userType: string, setLoading: Function, setErrorMessage: Function}> = ({setPhone, setEmail, onRegisterUserStart, onRunAsGuestUserStart, setLoading, setErrorMessage}) => {
  let history = useHistory();
  const { register, handleSubmit } = useForm(),
        [showError, setShowError] = useState(false),
        [showValError, setShowValError] = useState(false);   
        
  useEffect(() => {
    onRunAsGuestUserStart();
  }, []);
  
  const onSubmit = (data: any) => {
    if (!data.email) {
      displayTimedError();
      return;
    }
    
    const emailValidationPattern = new RegExp(EMAIL_VALIDATION_PATTERN);
    const phoneValidationPattern = new RegExp(PHONE_VALIDATION_PATTERN);
    let isEmail = emailValidationPattern.test(data.email); 
    let isPhone = phoneValidationPattern.test(data.email);
    if (!isEmail && !isPhone) {
      displayTimedInvalidDataError();
      return;
    }

    setShowValError(false);
    let userPhone; 
    let userEmail; 

    if (isPhone) {
      userPhone = data.email?.replace(/[- )(]/g,''); 
      setPhone(userPhone);
    } 
    
    if (isEmail) {
      userEmail = data.email;
      setEmail(userEmail);
    }
    setLoading(true);
    calloutHelper.sendLoginRequest(userPhone, userEmail).then(res=> {
      if (res) {
        history.push('/verifylogin');
      }
    }).catch(err => {
      console.log(err.response);
      setErrorMessage('Error finding user.');
    }).finally(() => {
      setLoading(false);
    })
  };

  const displayTimedError = () => {
    setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 3000);
  }

  const displayTimedInvalidDataError = () => {
    setShowValError(true);
    setTimeout(() => {
      setShowValError(false);
    }, 3000);
  }

  const onOneTimePaymentClick = () => {
    onRunAsGuestUserStart(); 
    history.push('/typeofpayment');
  }

  const onCreateRecurringPaymentsClick = () => {
    onRegisterUserStart();
    history.push('/register')
  }

  const displaySelectPaymentTypeComponent = () => {
    return (
      <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
        <IonRow>
          <IonCol class="p-1 light-gr-bg">
            <IonRow>
              <IonCol class="pl-3 pr-3 pt-1 pb-3 gr-border white-bg">
                <h2 className="ion-text-left">If you would like to make a one-time ACH payment without an account</h2>
                <IonButton onClick={onOneTimePaymentClick} size="large" expand="full" className="ion-text-wrap">ONE-TIME-PAYMENT</IonButton>
                  <h2 className="ion-text-left">If you would like to setup recurring ACH payments</h2>
                <IonButton onClick={onCreateRecurringPaymentsClick} size="large" expand="full" className="ion-text-wrap">RECURRING PAYMENTS</IonButton>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonCol>
    );
  }

  const displayLoginComponent = () => {
    return (
        <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
            <IonRow>
              <IonCol class="p-1 light-gr-bg">
                <IonRow>
                  <IonCol class="pl-3 pr-3 pt-1 pb-3 gr-border white-bg">
                    <h2 className="ion-text-left">Already have an account?<br/> Sign in below</h2>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <IonRow class="mt-2 mb-1">
                          <IonLabel>Email or Phone Number</IonLabel>
                        </IonRow>
                        <IonRow>
                        <IonInput class="ion-text-left gr-border" type="text" name="email" id="email" ref={register} required={true}></IonInput>
                        </IonRow>
                          {showError && <IonItem class="mt-1" color="danger"><p className="white-color">Please enter your email or phone number.</p></IonItem>}
                          {showValError && <IonItem class="mt-1" color="danger"><p className="white-color">Please enter a valid email address or phone number.</p></IonItem>}
                        <IonButton class="mt-2" size="large" expand="full" color="primary" type="submit">LOG IN</IonButton>
                    </form>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
        </IonCol>
    );
  }

  const displayHomeContent = () => {
    if (isMobile(isPlatform)) {
      return (
        <>
          {displayLoginComponent()}
          {displaySelectPaymentTypeComponent()}
        </>
      )
    } 
    return (
      <>
        {displaySelectPaymentTypeComponent()}
        {displayLoginComponent()}
      </>
    )
  }

  const getContainerHeight = () => {
    return (isMobile(isPlatform) ? {} : {height: "75%"})
  }
  
  return (
    <div className="container" style={getContainerHeight()}>
      <IonGrid>
        <IonRow>
          {displayHomeContent()}
        </IonRow>
      </IonGrid>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userType: state.userType
  }
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    onRegisterUserStart: () => dispatch(actions.registerUserStart()),
    onRunAsGuestUserStart: () => dispatch(actions.runAsGuestUserStart()), 
    setLoading: (showLoadingSpinner : boolean) => dispatch(actions.setPageLoading(showLoadingSpinner))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeContent);
