import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import '../pages/Style.css';
import PayerAssetPickList from './PayerAssetPickList'
import { 
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonLabel,
  IonInput,
  IonSelectOption,
  IonSelect,
  IonItem,
  IonTextarea,
  IonToast,
  IonText,
  IonCheckbox
} from '@ionic/react'; 
import {KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { setPageLoading } from '../store/actions/actions';
import calloutHelper from '../helpers/CalloutHelpers';
import {EMAIL_VALIDATION_PATTERN, PHONE_VALIDATION_PATTERN} from '../helpers/utils';


const QueuedtransactionForm: React.FC<{setCusip?(cusip: OtherAsset):any ,cusip: OtherAsset, userType: userTypes, assets?: Array<OtherAsset>, hidePicklist?: boolean, onBackClick?: Function, submitCallback: Function, formData:paymentDetailsForm, hideDisclosure?:boolean, nextAndBackParams?:nextAndBackParams, hideOneTimeOption?: boolean}> = ({setCusip, cusip, userType, assets, hidePicklist, onBackClick, submitCallback, formData, nextAndBackParams, hideDisclosure=true, hideOneTimeOption=false}) => {     
    let formRef = useRef<HTMLFormElement>(null); 
    const [errorMessage, setErrorMessage] = useState('');
    const [assetDescription, setAssetDescription] = useState(''),
          [processDate, ] = useState(''),
          [endDate, ] = useState(''),
          [frequency,setFrequency ] = useState(formData.frequency || 'One Time'),
          [hasReadAcknowledgements, setHasReadAcknowledgements] = useState(false)
    useEffect(() => {
    setAssetDescription(cusip.asset_description);
    }, [cusip]);

    useEffect(() => {
    if (errorMessage) {
        setTimeout(() => {
        setErrorMessage('');
        }, 3000);
    }
    }, [errorMessage]);
          
    const { register, handleSubmit, setValue, setError, errors, clearErrors, control } = useForm<paymentDetailsForm>({
      mode: 'onChange',
      defaultValues: formData
    });
    const setCustomError = (field: any) => {
        setError(field, {
          type: "manual"
        }); 
      }

    
    const validatePayment = (data: any) => {
        let errorFields: string[] = [];
        let isValid = true;
        let hasAsset = !!cusip.id;
        isValid = isValid && hasAsset; 
        if (!hasAsset) errorFields.push("invalid_cusip");
    
        let isPaymentValid = (data.payment_amount && +data.payment_amount > 0);
        isValid = isValid && isPaymentValid;
        if (!isPaymentValid) errorFields.push("payment_amount");
        
        let isProcessDateValid = (data.process_date !== null && (Date.parse(data.process_date) >= Date.now()));
        isValid = isValid && isProcessDateValid; 
        if (!isProcessDateValid) errorFields.push("process_date");
        
        if (userType !== 'guest'){
          let hasFrequency = !!data.frequency;
          isValid = isValid && hasFrequency;
          if (!hasFrequency) errorFields.push("frequency");
        }
    
        if (userType === 'guest'){
          const emailValidationPattern = new RegExp(EMAIL_VALIDATION_PATTERN);
          const phoneValidationPattern = new RegExp(PHONE_VALIDATION_PATTERN);
          
          let isEmailValid = !!data.email && emailValidationPattern.test(data.email); 
          isValid = isValid && isEmailValid;
          if (!isEmailValid) errorFields.push("email");
          
          let isPhoneValid = !!data.phone && phoneValidationPattern.test(data.phone);
          isValid = isValid && isPhoneValid;
          if (!isPhoneValid) errorFields.push("phone");
    
          let isNameValid = !!data.payers_name && data.payers_name.split(' ').length >= 2;
          isValid = isValid && isNameValid; 
          if (!isNameValid) errorFields.push("payers_name");
        }
    
        if (frequency !== 'One Time') {
          let isEndDateValid =(data.end_date !== null && (Date.parse(data.end_date) > Date.parse(data.process_date)));
          isValid = isValid && isEndDateValid;
          if (!isEndDateValid) errorFields.push("end_date");
        }
    
        if (!!data.amount_principal|| !!data.amount_interest) {
          let paymentTotalBreakdown = +data.amount_interest + +data.amount_principal;
          let isPaymentBreakdownValid = (paymentTotalBreakdown === +data.payment_amount);
          isValid = isValid && isPaymentBreakdownValid;
          if (!isPaymentBreakdownValid) {
            errorFields.push("amount_interest");
            errorFields.push("amount_principal")
          }
        }
        
        let isRoutingInputValid = !!data.bank_routing && data.bank_routing.length === 9;
        isValid = isValid && isRoutingInputValid;
        // if (!isRoutingInputValid) errorFields.push(errorFields.push("bank_routing")); // ? Why are we pushing the result of an array push?
        if (!isRoutingInputValid) errorFields.push("bank_routing");
    
        let isBankNameValid = !!data.bank_name;
        isValid = isValid && isBankNameValid;
        if (!isBankNameValid){
          errorFields.push("bank_name");
        } 
    
        let isBankAccountNumberValid = !!data.bank_account;
        isValid = isValid && isBankAccountNumberValid;
        if (!isBankAccountNumberValid) errorFields.push("bank_account");
    
        let isBankTypeValid = !!data.bank_account_type;
        isValid = isValid && isBankTypeValid;
        if (!isBankTypeValid) errorFields.push("bank_account_type");

        if(!hideDisclosure && !hasReadAcknowledgements) {
          isValid=false
          errorFields.push('read_acknowledgement')
        }

        console.log(`bank_name valid ${isBankNameValid} ${data.bank_name}`)
        console.log(`is form valid ${isValid}`)
        console.log(errorFields)
        console.log(data)
    
        if (errorFields.length > 0) {
          for (let i = 0 ; i < errorFields.length; i++) {
            setCustomError(errorFields[i]);
          }
        }
        return isValid;
      }
    
    const onSubmit = async (data: paymentDetailsForm) => {
        let isValid = validatePayment(data);
        if (!isValid) {
          setErrorMessage('Invalid fields');
          return; 
        }
        submitCallback(data)
    }

    const getAssetDescription = () => {
        if(hidePicklist && setCusip){
          return  <PayerAssetPickList assets={assets} setSelectedCusip={setCusip} defaultCusipId={cusip?.id}/>
        }
        return assetDescription
      }

      const convertStringDateToDate = (dateString?: string | undefined | null) => {
        if (dateString)
        {
          return new Date(dateString.replace(/-/g, '\/'))
        }
        return null
    }
    const handleFrequencyChange = (event: any) => {
        setFrequency(event.detail.value)
      }

      const validateRoutingNumber = (event: any) => {
        let routingNumber = event.target.value; 

        if (routingNumber && routingNumber.length === 9) {
          setPageLoading(true);
          calloutHelper.getIsBankRouteValid(routingNumber).then(result => {
            console.log(result)
            if (result.data.status === 'Valid') {
              setValue('bank_name', result.data.bank_name);
            } else  {
              setErrorMessage('Invalid Routing Number')
              setValue('bank_routing', '');
              setValue('bank_name', '');
            }
          }).catch(err => {
            setErrorMessage(err.message ? err.message : err);
            setValue('bank_routing', '');
            setValue('bank_name', '');
          }).finally(() => {
            setPageLoading(false);
          })
        }
      }

      const dispatchSubmit = () => {
        if (formRef && formRef.current) {
            formRef.current.dispatchEvent(new Event('submit', {cancelable:true}));
        }
      }
    
      const onNextClick = () => {
        clearErrors(); 
        dispatchSubmit();
      }

    return (
    <div className="container">
      <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <IonGrid>
            <IonToast isOpen={errorMessage !== ''} message={errorMessage} onDidDismiss={() => setErrorMessage('')} color="danger" position="top"></IonToast>
            <IonRow>
              <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
                <IonRow>
                  <IonCol class="p-1 light-gr-bg">
                    <IonRow>
                      <IonCol class="pl-3 pr-3 pt-1 pb-3 gr-border white-bg">
                        <h2 className="ion-text-left">Payment Details</h2>
                        <p>Please update the details for your payment and click “Next” to confirm.</p>
                        <p>If you are making a payment associated with multiple Equity Trust account holders, please enter the total payment amount and Equity Trust will automatically apply the payment splits.</p>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol class="p-1 light-gr-bg">
                    <IonRow>
                      <IonCol class="pl-3 pr-3 pt-1 pb-3 gr-border white-bg">
                          <h3 className="ion-text-left">Payment for *: {getAssetDescription()}</h3>
                          {errors.invalid_cusip && <IonText color='danger'> Select what this payment is for.</IonText>}
                          <IonRow>
                            <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                              <IonRow class="mt-2 mb-1">
                                <IonLabel>Payment Amount *</IonLabel>
                              </IonRow>
                              <IonRow>
                                <IonInput class="ion-text-left gr-border" type="number" name="payment_amount" id="payment_amount" ref={register} required={true}/>
                              </IonRow>
                              <IonRow>
                              {errors.payment_amount && <IonText color='danger'> Payment Amount should be greater than 0.</IonText>}
                            </IonRow>
                            </IonCol>
                            <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                              <IonRow class="mt-2 mb-1">
                                <IonLabel>Process Date *</IonLabel>
                              </IonRow>
                              <IonRow>
                                <IonItem className="ion-text-left gr-border date-width white-bg">
                                  <Controller name="process_date" control={control} defaultValue={null}  as={
                                    <KeyboardDatePicker
                                        className="date-width" name="process_date" disablePast={true} margin='normal'  placeholder="mm/dd/yyyy" value={convertStringDateToDate(processDate)}  format="MM/dd/yyyy" KeyboardButtonProps={{'aria-label': 'change-date'}} onChange={(date) => {
                                        }}/>
                                      }/>
                                </IonItem>
                                {errors.process_date && <IonText color='danger'> Select Process Date in the future.</IonText>}
                              </IonRow>
                            </IonCol>
                          </IonRow>
                          {userType !== 'guest' && (
                            <IonRow>
                              <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                                <IonRow class="mt-2 mb-1">
                                <IonLabel>Frequency *</IonLabel>
                                </IonRow>
                                <IonRow className="w-100">
                                  <IonItem className="w-100 gr-border">
                                    <IonSelect tabIndex={0} interface="action-sheet" mode="ios" value={frequency} ref={register} name='frequency' id='frequency' onIonChange={handleFrequencyChange}>
                                      {!hideOneTimeOption && (
                                        <IonSelectOption value="One Time">One Time</IonSelectOption>
                                      )}
                                      <IonSelectOption value="Monthly">Monthly</IonSelectOption>
                                      <IonSelectOption value="Quarterly">Quarterly</IonSelectOption>
                                      <IonSelectOption value="Annually">Annually</IonSelectOption>
                                    </IonSelect>
                                  </IonItem>
                                  {errors.frequency && <IonText color='danger'> Select frequency.</IonText>}
                                </IonRow>
                              </IonCol>
                              {frequency !== 'One Time' && (
                                <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                                  <IonRow class="mt-2 mb-1">
                                    <IonLabel>End Date *</IonLabel>
                                  </IonRow>
                                  <IonRow>
                                    <IonItem className="ion-text-left gr-border date-width">
                                      <Controller name="end_date" control={control} defaultValue={null}  as={
                                      <KeyboardDatePicker
                                        className="date-width" name="end_date" disablePast={true} margin='normal'  placeholder="mm/dd/yyyy" value={convertStringDateToDate(endDate)}  format="MM/dd/yyyy" KeyboardButtonProps={{'aria-label': 'change-date'}} onChange={(date) => {
                                        }}/>
                                      }/>
                                    </IonItem>
                                    {errors.end_date && <IonText color='danger'> Select an end date that is after the process date.</IonText>}
                                  </IonRow>
                                </IonCol>
                              )}
                            </IonRow>
                          )}
                        </IonCol>
                      </IonRow>
                    </IonCol>
                  </IonRow>
                  {cusip.type === 'note' && <IonRow>
                    <IonCol class="p-1 light-gr-bg">
                      <IonRow>
                        <IonCol class="pl-3 pr-3 pt-1 pb-3 gr-border white-bg">
                          <h3 className="ion-text-left">Payment Breakdown (Optional)</h3>
                          <p>If you would like to specify the breakdown of your payment, please enter amounts for Principal and Interest. <b>NOTE:</b> These amounts must equal the Payment Amount.</p>
                          <IonRow>
                            <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                              <IonRow class="mt-2 mb-1">
                                <IonLabel>Amount Towards Principal (Optional)</IonLabel>
                              </IonRow>
                              <IonRow>
                                <IonInput class="ion-text-left gr-border" type="number" name="amount_principal" id="amount_principal" ref={register}/>
                                {errors.amount_principal && <IonText color='danger'> Sum of Principal and Interest must equal that of your payment amount.</IonText>}
                              </IonRow>
                            </IonCol>
                            <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                              <IonRow class="mt-2 mb-1">
                                <IonLabel>Amount Towards Interest (Optional)</IonLabel>
                              </IonRow>
                              <IonRow>
                                <IonInput class="ion-text-left gr-border" type="number" name="amount_interest" id="amount_interest" ref={register}/>
                                {errors.amount_interest && <IonText color='danger'> Sum of Principal and Interest must equal that of your payment amount.</IonText>}
                              </IonRow>
                            </IonCol>
                          </IonRow>
                        </IonCol>
                      </IonRow>
                    </IonCol>
                  </IonRow>}
                  <IonRow>
                    <IonCol class="p-1 light-gr-bg">
                      <IonRow>
                        <IonCol class="pl-3 pr-3 pt-1 pb-3 gr-border white-bg">
                          <IonRow>
                            <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                              <IonRow class="mt-2 mb-1">
                                <IonLabel>Bank Routing Number *</IonLabel>
                              </IonRow>
                              <IonRow>
                                <IonInput class="ion-text-left gr-border" type="number" name="bank_routing" id="bank_routing" ref={register} onIonChange={validateRoutingNumber}/>
                              </IonRow>
                              {errors.bank_routing && <IonText color='danger'> Enter valid 9 digit routing number.</IonText>}
                            </IonCol>
                            <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                              <IonRow class="mt-2 mb-1">
                                <IonLabel>Bank Name *</IonLabel>
                              </IonRow>
                              <IonRow>
                                <IonInput class="ion-text-left gr-border" style={{'caret-color': 'transparent', 'color': 'grey'}} type="text" name="bank_name" id="bank_name" ref={register} maxlength={100} readonly/>
                              </IonRow>
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                              <IonRow class="mt-2 mb-1">
                                <IonLabel>Bank Account Number *</IonLabel>
                              </IonRow>
                              <IonRow>
                                <IonInput class="ion-text-left gr-border" name='bank_account' maxlength={20} ref={register}/>
                              </IonRow>
                              {errors.bank_account && <IonText color='danger'> Enter Bank Account Number</IonText>}
                            </IonCol>
                            <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                              <IonRow class="mt-2 mb-1">
                                <IonLabel>Bank Account Type *</IonLabel>
                              </IonRow>
                              <IonRow className="w-100">
                                  <IonItem className="w-100 gr-border">
                                    <IonSelect tabIndex={0} interface="action-sheet" mode="ios" name='bank_account_type' ref={register}>
                                      <IonSelectOption value="Checking">Checking</IonSelectOption>
                                      <IonSelectOption value="Savings">Savings</IonSelectOption>
                                    </IonSelect>
                                  </IonItem>
                                  {errors.bank_account_type && <IonText color='danger'> Select Bank Account Type.</IonText>}
                                </IonRow>
                            </IonCol>
                          </IonRow>
                        </IonCol>
                      </IonRow>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol class="p-1 light-gr-bg">
                      <IonRow>
                        <IonCol class="pl-3 pr-3 pt-1 pb-3 gr-border white-bg">
                          <IonRow>
                            {userType === 'guest' && (
                              <>
                                <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                                  <IonRow class="mt-2 mb-1">
                                    <IonLabel>Phone Number *</IonLabel>
                                  </IonRow>
                                  <IonRow>
                                    <IonInput class="ion-text-left gr-border" type="tel" name="phone" id="phone" ref={register}/>
                                  </IonRow>
                                  {errors.phone && <IonText color='danger'> Enter valid phone.</IonText>}
                                </IonCol>
                                <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                                  <IonRow class="mt-2 mb-1">
                                    <IonLabel>Email *</IonLabel>
                                  </IonRow>
                                  <IonRow>
                                    <IonInput class="ion-text-left gr-border" type="email" name="email" id="email" maxlength={80} ref={register}></IonInput>
                                  </IonRow>
                                  {errors.email && <IonText color='danger'> Enter valid email.</IonText>}
                                </IonCol>
                                <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                                  <IonRow className="mt-2 mb-1">
                                    <IonLabel>Enter Your Name As Your Signature *</IonLabel>
                                  </IonRow>
                                  <IonRow>
                                    <IonInput className="ion-text-left gr-border" name="payers_name" ref={register} maxlength={100}/>
                                  </IonRow>
                                  {errors.payers_name && <IonText color='danger'> Signature required. Please enter first and last name.</IonText>}
                                </IonCol>
                              </>
                            )}
                            <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                              <IonRow class="mt-2 mb-1">
                                <IonLabel>Notes (Maxlength = 250 chars)
                                </IonLabel>
                              </IonRow>
                              <IonRow>
                                <IonTextarea class="ion-text-left mt-0 gr-border" name="notes" id="notes" ref={register} maxlength={250}/>
                              </IonRow>
                            </IonCol>
                          </IonRow>
                        </IonCol>
                      </IonRow>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                    <IonRow>
                    {!hideDisclosure && <IonCol>
                    {errors.read_acknowledgement && <IonText color='danger'> Plese read the disclosure and click the checkox.</IonText>}
                      <p><IonCheckbox checked={hasReadAcknowledgements} onIonChange={event => setHasReadAcknowledgements(event.detail.checked)}></IonCheckbox> <b>By checking this box and clicking "Submit", you affirm that you have fully read, acknowledge, and agree to the following statements:</b> I authorize Equity Trust to initiate preauthorized electronic funds transfers and debit the authorized amount indicated from the designated checking account listed. I understand this debit will be initiated on a recurring schedule based on my selection. If the process date falls on a weekend or holiday, the account will be debited the following business day.</p>

                      <p>I acknowledge and agree that this transaction will be subject to the provisions of the Uniform Electronic Transactions Act, as passed in the state where the Custodian, Equity Trust Company, is organized, and the federal Electronic Signature in Global and National Commerce Act, as those laws pertain to electronic communication, electronic signatures, and electronic storage of Equity Trust Company account records. I understand that, in lieu of the retention of the original records, Equity Trust and Equity Trust Company may cause any, or all, of their records, and records at any time in their custody, to be photographed or otherwise reproduced to permanent form, and any such photograph or reproduction shall have the same force and effect as the original thereof and may be admitted in evidence equally with the original if permitted by law.</p>
                    </IonCol>}
                  </IonRow>
                    <IonRow>
                      <IonCol class="mb-5">
                    <IonButton class="ion-float-right" size="large" onClick={onNextClick}>{nextAndBackParams?.nextButtonText}</IonButton>
                    <IonButton color="light" class="ion-float-right" onClick={nextAndBackParams?.backButtonCallback} size="large">{nextAndBackParams?.backButtonText}</IonButton>
                      </IonCol>
                  </IonRow>
                    </IonCol>
                  </IonRow>
              </IonCol>
            </IonRow>
          </IonGrid>
        </MuiPickersUtilsProvider>
      </form>
    </div>
  );
};

export default QueuedtransactionForm