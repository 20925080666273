import React, { useEffect, useState } from 'react';
import '../pages/Style.css';
import { connect } from 'react-redux';
import { setPageLoading, setCusip } from '../store/actions/actions';
import { useHistory } from 'react-router';
import calloutHelper from '../helpers/CalloutHelpers';
import QueuedtransactionForm from './QueuedtransactionForm';

const PaymentDetails: React.FC<{setCusip(cusip: OtherAsset):any ,cusip: OtherAsset, userType: userTypes, assets?: Array<OtherAsset>, newAsset: boolean}> = ({setCusip, cusip, userType, assets, newAsset}) => {
  const history = useHistory();
  const [ formData, setFormData ] = useState<paymentDetailsForm>()
  const [ hidePicklist, setHidePicklist ] = useState(false)
  const [ filteredAssets, setFilteredAssets ] = useState<Array<OtherAsset>>()

  useEffect(() =>{
    setPageLoading(true);
    calloutHelper.getFormData().then((result)=>{
      setFormData(result.data)
    }).finally(()=>{
      setPageLoading(false);
    })
  },[])

  useEffect(()=>{
    setFilteredAssets(assets?.filter((value)=>{return !value.isGeneric}))
  },[assets])

  useEffect(()=>{
    if(filteredAssets?.length === 1 && cusip.id === ''){
      setCusip(filteredAssets[0])
    }

    setHidePicklist((!newAsset && userType !== 'guest') && !(filteredAssets?.length === 1))
  },[filteredAssets])

  const updateRemoteFormData = (formData: paymentDetailsForm) => {
    setPageLoading(true)
    if(userType === 'guest'){
      formData.frequency = 'One Time';
    }

    return calloutHelper.updateFormData(formData).finally(()=>{
      setPageLoading(false);
    })
  }


  const onSubmit = async (data: paymentDetailsForm) => {
    await updateRemoteFormData(data)

    if (userType === 'guest') {
      history.push({
        pathname: '/ReviewPayment', 
        state: {
          email: data.email, 
          phone: data.phone?.replace(/[- )(]/g,''), 
          name: data.payers_name
        }
      })
    } else {
      history.push('/ReviewPayment');
    }
  }

  let nextAndBackButtons: nextAndBackParams = {
    nextButtonText:'Next',
    backButtonText:'Back',
    backButtonCallback:(event)=>{
      if(newAsset){
        history.push('/TypeOfPayment')
      }else{
        history.push('/')
      }
  
    }
  }

  if(formData) { return <QueuedtransactionForm setCusip={setCusip} cusip={cusip} userType={userType} assets={filteredAssets} hidePicklist={hidePicklist} submitCallback={onSubmit} formData={formData} nextAndBackParams={nextAndBackButtons}/>} else{
    return <h1>Loading...</h1>
  }

};

const mapStateToProps = (state: reducerState) => {
  return {
    cusip: state.cusip,
    userType: state.userType,
    assets: state.assets,
    newAsset: state.newAsset
  }
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    setCusip: (property: OtherAsset) => dispatch(setCusip(property)),
    setPageLoading: (isPageLoading: boolean) => dispatch(setPageLoading(isPageLoading))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetails);
