import React, { useEffect, useState } from 'react';
import { IonSelect, IonSelectOption, IonItem } from '@ionic/react';
const PayerAssetPickList: React.FC<{setSelectedCusip(cusip: OtherAsset):any, assets?: Array<OtherAsset>, defaultCusipId?: string}> = ({setSelectedCusip, assets, defaultCusipId}) => {
    const [localCusipId, setLocalCusipId] = useState<string|undefined>(defaultCusipId)
    const [ionSelectStyle, setIonSelectStyle] = useState("");

    useEffect(() => {
        if (assets && assets.length && assets.length > 5) {
            setIonSelectStyle("long-select");
        }
    }, [assets])

    const setCusipForParent = (cusipId: string) =>{
        let cusip = assets?.find((value)=>{
            if(value.id === cusipId){
                return value;
            }
        })
        if(cusip){
            setSelectedCusip(cusip);
        }
    }

    return (<div>
        {assets && <IonItem>
            <IonSelect interfaceOptions={{cssClass: ionSelectStyle, animated: "false", mode: "ios"}} tabIndex={0} value={localCusipId} interface='action-sheet' placeholder='Please Select' onIonChange={(event)=>{setLocalCusipId(event.detail.value); setCusipForParent(event.detail.value);}}>
                {assets?.map((value)=>{
                    if(value.asset_description){
                        return <IonSelectOption key={value.id} value={value.id}>{value?.asset_description}</IonSelectOption>
                    }
                })}
            </IonSelect>
        </IonItem>}
    </div>)
}

export default PayerAssetPickList