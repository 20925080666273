import React, { useEffect, useState } from 'react';
import { 
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonText,
  IonCheckbox,
  IonToast
} from '@ionic/react'; 
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import calloutHelpers from '../helpers/CalloutHelpers';
import * as actions from '../store/actions/actions';
import { AxiosResponse } from 'axios';

const ReviewPayment: React.FC<{userType: userTypes, userId: string, logoutIfGuest: Function, cusip: OtherAsset, resetCusip: Function, updateAssets(assets:Array<OtherAsset>):reducerState, setPageLoading(isPageLoading: boolean): any, updateTransactions(queuedTransactions:outstandingTransactions):any}> = ({userType, userId, cusip, resetCusip, updateAssets, setPageLoading, updateTransactions}) => {
  const history = useHistory();
  const location = useLocation();
  const [email, setEmail] = useState(''),
        [phone, setPhone] = useState(''),
        [name, setName] = useState(''),
        [errorMessage, setErrorMessage] = useState(''),
        [hasReadAcknowledgements, setHasReadAcknowledgements] = useState(false);
  const [formData, setFormData] = useState<paymentDetailsForm>({});

  useEffect(() => {
        if (location && location.state) {
            let locationState: any = location.state; 
            setEmail(locationState.email ? locationState.email : '');
            setPhone(locationState.phone ? locationState.phone : '');
            setName(locationState.name ? locationState.name : '');
        }
    }, [location]);

  useEffect(() =>{
    setPageLoading(true);
    calloutHelpers.getFormData().then((result)=>{
      setFormData(result.data);      
    }).finally(()=>{
      setPageLoading(false);
    })
  },[])

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    }
  }, [errorMessage]);


  const submitPayment = async () => {
    if (!hasReadAcknowledgements) {
      setErrorMessage('Please read acknowledgements and check the checkbox.');
      return; 
    }
    setPageLoading(true);
    let result:AxiosResponse<SubmitAssetResponse> | undefined;
    try{ 
      result = await calloutHelpers.submitAsset({
      asset: cusip,
      oneTime: true,
      userId
    })
    }catch(error){
      setErrorMessage('TThe payment failed. Please wait a moment and try again.')
      setPageLoading(false);
      return;
    }finally{
      if(result?.data.status === 'failed'){
        setErrorMessage('The payment failed. Please wait a moment and try again.')
        setPageLoading(false);
        return;
      }
    }

    setPageLoading(false);
    if(result?.data?.assets !== undefined){
      updateAssets(result.data.assets);
    }
    if(result?.data?.queuedTransactions !== undefined){
      updateTransactions(result.data.queuedTransactions)
    }

    resetCusip(userType);
    if (userType === 'guest') {
      history.push({
        pathname: '/paymentsuccess', 
        state: {email, phone, name}
      });
    } else {
      history.push('/paymentsuccess');
    }
  }

  return (
    <div className="container">
        <IonToast isOpen={errorMessage !== ''} message={errorMessage} onDidDismiss={() => setErrorMessage('')} color="danger" position="top"></IonToast>
        <IonGrid>
          <IonRow>
            <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
              <IonRow>
                <IonCol class="p-1 light-gr-bg">
                  <IonRow>
                    <IonCol class="pl-3 pr-3 pt-1 pb-3 gr-border white-bg">
                      <h2 className="ion-text-left">Review Payment</h2>
                      <p>Please review the payment details below and click “Submit” to save.</p>
                      <p>If a payment falls on a weekend or holiday it will be processed the following business day. Please allow up to two business days for funds to be debited from your account.</p>
                    </IonCol>
                  </IonRow>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol class="p-1 light-gr-bg">
                  <IonRow>
                    <IonCol class="pl-3 pr-3 pt-1 pb-3 gr-border white-bg">
                      <IonRow>
                        <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                          <IonRow class="mt-1 mb-1">
                            <IonText>Payment Amount</IonText>
                          </IonRow>
                          <IonRow class="mt-1 mb-1">
                            <IonText>{formData.payment_amount}</IonText>
                          </IonRow>
                        </IonCol>
                        <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                          <IonRow class="mt-1 mb-1">
                            <IonText>Bank Routing Number</IonText>
                          </IonRow>
                          <IonRow class="mt-1 mb-1">
                            <IonText>
                             {formData.bank_routing}
                            </IonText>
                          </IonRow>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                          <IonRow class="mt-1 mb-1">
                            <IonText>Bank Account Number</IonText>
                          </IonRow>
                          <IonRow class="mt-1 mb-1">
                          <IonText>{formData.bank_account}</IonText>
                          </IonRow>
                        </IonCol>
                        <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                          <IonRow class="mt-1 mb-1">
                            <IonText>Bank Account Type</IonText>
                          </IonRow>
                          <IonRow class="mt-1 mb-1">
                            <IonText>
                             {formData.bank_account_type}
                            </IonText>
                          </IonRow>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                          <IonRow class="mt-1 mb-1">
                            <IonText>Process Date</IonText>
                          </IonRow>
                          <IonRow class="mt-1 mb-1">
                            <IonText>{typeof formData.process_date === 'string' ? new Date(formData.process_date).toDateString() : formData.process_date} </IonText>
                          </IonRow>
                        </IonCol>
                        <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                          <IonRow class="mt-1 mb-1">
                            <IonText>Frequency</IonText>
                          </IonRow>
                          <IonRow class="mt-1 mb-1">
                          <IonText>{formData.frequency}</IonText>
                          </IonRow>
                        </IonCol>
                      </IonRow>
                      {formData.end_date && 
                      <IonRow>
                        <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                          <IonRow class="mt-1 mb-1">
                            <IonText>End Date</IonText>
                          </IonRow>
                          <IonRow class="mt-1 mb-1">
                            <IonText>{typeof formData.end_date === 'string' ? new Date(formData.end_date).toDateString() : formData.end_date}</IonText>
                          </IonRow>
                        </IonCol>
                      </IonRow>}
                    </IonCol>
                  </IonRow>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol class="pl-3 pr-3 pt-1 pb-3 gr-border">
                  <IonRow>
                    <IonCol>
                      <p><IonCheckbox checked={hasReadAcknowledgements} onIonChange={event => setHasReadAcknowledgements(event.detail.checked)}></IonCheckbox> <b>By checking this box and clicking "Submit", you affirm that you have fully read, acknowledge, and agree to the following statements:</b> I authorize Equity Trust to initiate preauthorized electronic funds transfers and debit the authorized amount indicated from the designated checking account listed. I understand this debit will be initiated on a recurring schedule based on my selection. If the process date falls on a weekend or holiday, the account will be debited the following business day.</p>

                      <p>I acknowledge and agree that this transaction will be subject to the provisions of the Uniform Electronic Transactions Act, as passed in the state where the Custodian, Equity Trust Company, is organized, and the federal Electronic Signature in Global and National Commerce Act, as those laws pertain to electronic communication, electronic signatures, and electronic storage of Equity Trust Company account records. I understand that, in lieu of the retention of the original records, Midland IRA, Inc. and Equity Trust Company may cause any, or all, of their records, and records at any time in their custody, to be photographed or otherwise reproduced to permanent form, and any such photograph or reproduction shall have the same force and effect as the original thereof and may be admitted in evidence equally with the original if permitted by law.</p>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonButton class="ion-float-right" onClick={submitPayment} routerDirection="back" size="large">Submit</IonButton>
                      <IonButton color="light" class="ion-float-right" routerLink="/PaymentDetails" size="large">Back</IonButton>
                    </IonCol>
                  </IonRow>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonGrid>
    </div>
  );
};

const mapStateToProps = (state: reducerState) => {
  return {
    userType: state.userType,
    cusip: state.cusip,
    userId: state.userId
  }
}

const mapDispatchToProps = (dispatch: Function) =>{
  return{
    logoutIfGuest: () => dispatch(actions.logout()),
    resetCusip: (userType: userTypes) => {actions.resetCusipState(dispatch, userType)},
    updateAssets: (assets: Array<OtherAsset>) => dispatch(actions.updateAssets(assets)),
    setPageLoading: (isPageLoading: boolean) => dispatch(actions.setPageLoading(isPageLoading)),
    updateTransactions: (newQueuedTransactions: outstandingTransactions) => dispatch(actions.updateTransactions(newQueuedTransactions))
  }
}


export default connect(mapStateToProps,mapDispatchToProps)(ReviewPayment);
