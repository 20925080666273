import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import '../pages/Style.css';
import progressBarStepTwo from '../images/step-two.svg';
import { 
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonLabel,
  IonInput,
  IonItem,
} from '@ionic/react';
import AlternateLookup from '../components/AlternateLookup';
import calloutHelper from '../helpers/CalloutHelpers';
import { useHistory } from 'react-router';
import * as actions from '../store/actions/actions';
import { connect } from 'react-redux';
import { GENERIC_NOTE_CUSIP_TEXT, AssetLookupContainer } from '../helpers/utils';


const NotePayment: React.FC<AssetLookupContainer> = ({userType, setCusip, resetCusip, setNewAsset, setTimeoutTime}) => {
  let history = useHistory();
  const setNewAssetTrueAndTransition = ()=>{
    setNewAsset();
    history.push('/paymentdetails')
  }
  type form = {noteid?:string, altlookup?:string}
  const { register, handleSubmit, setValue } = useForm<form>({
    mode: "onChange",
  }),
        [showError, setShowError] = useState(false),
        [showValError, setShowValError] = useState(false),
        [showAlternateLookup, setShowAlternateLookup] = useState(false);

  const noteIdPattern = /^[A-Za-z0-9-_&\s.,#]{9}$/g;

  const onSubmit = (data: form) => {
    if (!data.noteid && !data.altlookup) {
      showTimedError();
      return;
    }
    
    if (data.noteid &&!noteIdPattern.test(data.noteid)) {
        showTimedValidationError();
        return;
    } 

    setShowValError(false);
    if (data.noteid) {
     return findCusipAndRedirect(data);
    }

    if (data.altlookup && data.altlookup.trim() !== '') {
      //callout to get cusip or resolve cusip on insert
      let cusipId = GENERIC_NOTE_CUSIP_TEXT
      let cusip: OtherAsset = {
        id: cusipId,
        asset_description: data.altlookup,
        name: 'Generic Note',
        type:'note',
        isGeneric: true
      }
      setCusip(cusip);
      
      if (userType !== 'guest') {
        setNewAssetTrueAndTransition();
      } else {
        calloutHelper.startGuestUserSession(cusip, 'cusip').then(result => {
          if (result.data.expirationTime){
            let expirationTime = Date.parse(result.data.expirationTime);
            let timeout: number = expirationTime - Date.now(); 
            setTimeoutTime(timeout);
          }
          
          setNewAssetTrueAndTransition();
          return;
        }).catch(err => {
          console.log(err);
          return;
        })
      }
    }
  }

  const findCusipAndRedirect = async(data : form) => {
    try {
      if(!data.noteid){
        return
      }
      let cusipResult = await calloutHelper.findCusip(data.noteid);
      if(!cusipResult.data.asset){
        showTimedError();
        return
      }

      setCusip({...cusipResult.data.asset, type:'note'});
      if (userType !== 'guest') {
        setNewAssetTrueAndTransition();
        return;
      }

      let sessionResult = await calloutHelper.startGuestUserSession(cusipResult.data.asset, 'cusip');
      if (sessionResult) {
        setNewAssetTrueAndTransition();
      }
    } catch (err) {
      console.log(err);
      resetCusip(userType);
      showTimedError();
    }
  }

  const showTimedValidationError = useCallback(() => {
    setShowValError(true);
        setTimeout(() => {
          setShowValError(false);
        }, 3000);
  }, []);

  const showTimedError = useCallback(() => {
    setShowError(true);
    setShowAlternateLookup(true);
    setTimeout(() => {
      setShowError(false);
    }, 3000);
  }
  , []);

  const onNoteNameChange = useCallback((event: any) => {
    if (event.detail.value) {
      setValue('altlookup', '');
      setShowAlternateLookup(false);
    }
  }, [setValue]);

  const onAltLookupChange = () => {
    setValue('noteid', '');
  }

  return (
    <div className="container">
      <IonGrid>
        <IonRow>
        <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
            <IonRow>
              <IonCol class="p-1 light-gr-bg">
                <IonRow>
                  <IonCol class="pl-3 pr-3 pt-1 pb-3 gr-border white-bg">
                    <IonRow class="divider">
                      <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                        <img src={progressBarStepTwo} alt="progress bar" />
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        <h2 className="ion-text-left">Please search the note ID.</h2>
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <IonRow>
                            <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
                                <IonRow class="mt-2 mb-1">
                                  <IonLabel>Note ID</IonLabel>
                                </IonRow>
                                <IonRow>
                                  <IonInput class="ion-text-left gr-border" type="text" name="noteid" id="noteid" ref={register} onIonChange={onNoteNameChange}></IonInput>
                                </IonRow>
                                {showError && <IonItem class="mt-1" color="danger"><p className="white-color">Please enter the note ID for the account which you wish to make a note payment towards.</p></IonItem>}
                                {showValError && <IonItem class="mt-1" color="danger"><p className="white-color">Please enter a valid note ID.</p></IonItem>}
                                  {showAlternateLookup && <AlternateLookup register={register} onChange={onAltLookupChange}/>}
                                <IonButton class="mt-2" color="primary" size="large" type="submit">SUBMIT</IonButton>
                              </IonCol>
                            </IonRow>
                          </form>
                        <IonRow class="mt-5 divider" />
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userType: state.userType
  }
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    setCusip: (cusip: OtherAsset) => dispatch(actions.setCusip(cusip)),
    resetCusip: (userType: userTypes) => actions.resetCusipState(dispatch, userType),
    setNewAsset: () => dispatch(actions.setNewAsset(true))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotePayment);
