//These two constants are shared by server and client, do not delete
export const GENERIC_RE_CUSIP_TEXT = 'GENERIC_CUSIP_RE'
export const GENERIC_NOTE_CUSIP_TEXT = 'GENERIC_CUSIP_NOTE'

export interface AssetLookupContainer {
    userType: string,
    setCusip(cusip:OtherAsset):void,
    resetCusip: Function,
    setNewAsset: Function,
    setTimeoutTime: Function
}

export interface TabToolbar {
    dispatchLogout:Function, 
    setTimeoutTime: Function
}

export const isMobile = (isPlatform: Function) => {
    return (isPlatform('iphone') || isPlatform('android') || isPlatform('ipad'));
  }

export const EMAIL_VALIDATION_PATTERN =  "^([a-zA-Z0-9_\\-.+]+)@([a-zA-Z0-9_\\-.+]+)\\.([a-zA-Z]{2,5})$";
export const PHONE_VALIDATION_PATTERN = "^(\\+\\d{1,2}\s?)?1?-?\\.?\\s?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$";