import React, { useEffect, useState } from 'react';
import { 
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
  } from '@ionic/react'; 
import { connect } from 'react-redux';
import * as actions from '../store/actions/actions';
import { useHistory, useLocation } from 'react-router';
import calloutHelper from '../helpers/CalloutHelpers';

const PaymentSuccess : React.FC<{userType: string, logoutForGuest: Function, setTimeoutTime: Function, setErrorMessage: Function}> = ({userType, logoutForGuest, setTimeoutTime, setErrorMessage}) => {
    const history = useHistory();
    const location = useLocation();
    const [email, setEmail] = useState(''),
    [name, setName] = useState(''),
    [phone, setPhone] = useState('');

    useEffect(() => {
        const logOutOnUnload = (ev:any) => {
            return logoutAndRedirectHome();
        }
        if (userType === 'guest') {
            window.addEventListener('beforeunload', logOutOnUnload)
            let timeout = setTimeout(() => {
                logoutAndRedirectHome();
            }, 300000);

            return()=>{
                window.removeEventListener('beforeunload',logOutOnUnload);
                clearTimeout(timeout);
            }
        }
    }, [userType, logoutForGuest, history]);

    useEffect(() => {
        if (location && location.state) {
            let locationState: any = location.state; 
            setEmail(locationState.email ? locationState.email : '');
            setPhone(locationState.phone ? locationState.phone : '');
            setName(locationState.name ? locationState.name : '');
        }
    }, [location]);

    const logoutAndRedirectHome = () => {
        calloutHelper.signout().then(() => {
            logoutForGuest();
            history.push('/');
            setTimeoutTime(undefined);
        }).catch(err => {
            setErrorMessage('An error has occurred');
        })
    }

    const onGuestSignUpClick = () => {
        history.push({
            pathname: '/register',
            state: {email,phone, name}
        });
    }

    return (
        <div className="container">
        <IonGrid>
          <IonRow>
            <IonCol className="p-1" sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
              <IonRow>
                <IonCol className="p-1 light-gr-bg">
                  <IonRow>
                    <IonCol className="pl-3 pr-3 pt-1 pb-3 gr-border white-bg">
                        <h2 className='ion-text-left'> Payment Success! </h2>
                        <p>Your payment has been made successfully! {userType !== 'guest' && 'You can view scheduled payment on the Home Tab'}</p>
                        {userType !== 'guest' && (
                            <div className='ion-text-center'>
                                <IonButton color="secondary" routerLink="/PaymentHome" size="large">Return Home</IonButton>
                            </div>
                        )} 
                    </IonCol>
                  </IonRow>
                  </IonCol>
                </IonRow>
                  {userType === 'guest' && (
                    <IonRow>
                        <IonCol class="p-1 light-gr-bg">
                            <IonRow>
                                <IonCol class="pl-3 pr-3 pt-1 pb-3 gr-border white-bg">
                                    <h3 className="ion-text-left">Want to save yourself time on your next payment?</h3>
                                    <div className='ion-text-center'>
                                        <IonButton onClick={onGuestSignUpClick}>Click Here To Sign Up!</IonButton>
                                    </div>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                  )}
                </IonCol>
              </IonRow>
        </IonGrid>
      </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        userType: state.userType
    }
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        logoutForGuest : () => dispatch(actions.logout())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess); 