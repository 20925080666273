import React, { useEffect } from 'react';
import '../pages/Style.css';
import progressBarStepOne from '../images/step-one.svg';
import {resetCusipState} from '../store/actions/actions'
import { connect } from 'react-redux';
import { 
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
} from '@ionic/react';

interface ContainerProps { resetCusip:Function, userType:userTypes }

const TypeOfPayment: React.FC<ContainerProps> = ({resetCusip, userType}) => {
  useEffect(()=>{
    resetCusip(userType);
  },[])

  return (
    <div className="container">
      <IonGrid>
        <IonRow>
          <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
            <IonRow>
              <IonCol class="p-1 light-gr-bg">
                <IonRow>
                  <IonCol class="pl-3 pr-3 pt-1 pb-3 gr-border white-bg">
                    <IonRow class="divider">
                      <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                        <img src={progressBarStepOne} alt="progress bar" />
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        <h2 className="ion-text-left">Please make a selection.</h2>
                        <IonRow>
                          <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                            <IonButton routerLink="/RentPayment" size="large" expand="full">
                                RENT PAYMENT
                            </IonButton>
                          </IonCol>
                          <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                            <IonButton routerLink="/NotePayment" size="large" expand="full">
                                NOTE PAYMENT
                            </IonButton>
                          </IonCol>
                        </IonRow>
                        <IonRow class="mt-5 divider" />
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Function) =>{
  return{
    resetCusip: (userType: userTypes) => resetCusipState(dispatch, userType)
  }
}

const mapStateToProps = (state: reducerState) =>{
  return {
    userType: state.userType
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TypeOfPayment);
