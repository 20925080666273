import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {  IonGrid, IonCol } from '@ionic/react';
import calloutHelper from '../helpers/CalloutHelpers';
import * as actions from '../store/actions/actions';
import { connect } from 'react-redux';

const JWTVerify: React.FC<{jwtToken:string, userType: userTypes, onLoggedIn: Function, onRegisterUserSuccess: Function, setTimeoutTime: Function}> = ({jwtToken, userType, onLoggedIn, onRegisterUserSuccess, setTimeoutTime}) => {
    const history = useHistory();

    useEffect(() => {
        if (!jwtToken || jwtToken.trim() === '') {
            history.push('/');
            return;
        }

        calloutHelper.verifyJWTRequest(jwtToken).then(result => {
            if (!result.data.userId) {
                history.push('/');
                return;
            }

            if (result.data.expirationTime) {
                let expirationTime = Date.parse(result.data.expirationTime);
                let timeout: number = expirationTime - Date.now();
                setTimeoutTime(timeout);
            }
    
            if (result.data.type === 'registering') {
                onRegisterUserSuccess(result.data.userId);
                history.push('/typeofpayment');
                return;
            }
            onLoggedIn(result.data.userId, result.data.assetsForPayer, result.data.queuedTransactions);
        }).catch(err => {
            history.push('/');
        });
    }, [jwtToken, history, onLoggedIn])

    useEffect(()=>{
        if (userType === 'loggedIn') {
            history.push('/paymenthome');
            return
        }
    },[userType])

    return (
        <IonGrid>
            <IonCol>
                Loading Page...
            </IonCol>
        </IonGrid>
    );
}

const mapStateToProps = (state: any) => {
    return {
      userType: state.userType
    }
  }
  
  const mapDispatchToProps = (dispatch:Function) => {
    return {
      onLoggedIn: (userId: string, assets: Array<OtherAsset>, queuedTransactions: outstandingTransactions) => dispatch(actions.loginSuccess(userId, assets, queuedTransactions)),
      onRegisterUserSuccess: (userId: string) => dispatch(actions.registerUserSuccess(userId)),
      logout: () => dispatch(actions.logout())
    }
  
  }

export default connect(mapStateToProps, mapDispatchToProps)(JWTVerify); 