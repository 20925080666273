import React from 'react';
import '../pages/Style.css';
import logoDarkMode from '../images/logo-light.png';
import logoLightMode from '../images/logo-dark.png';
import { 
  IonHeader,
  IonToolbar,
  IonRow,
  IonCol,
  isPlatform,
} from '@ionic/react';
import PaymentTabs from './PaymentTabs';
import { connect } from 'react-redux';
import {isMobile} from '../helpers/utils';

const Header: React.FC<{userId: string, setTimeoutTime: Function}> = ({userId, setTimeoutTime}) => {
  const showPaymentTabs = () => {
    if (userId) return true; 
    return false; 
  }

  const isDarkMode = () => window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

  return (
      <IonHeader class="header-shadow">
        <IonToolbar>
          <IonRow class="header-row">
            <IonCol className="ion-no-padding">
              <a href="/"><img src={isDarkMode() ? logoDarkMode : logoLightMode} width="120" alt="Equity Trust logo"/></a>
              {!isMobile(isPlatform) && (
              <div style={{display: "inline-block", width: "75%", position: "relative", bottom: "15%", left: "2%"}} className="ion-text-center">
                <h1>Welcome to Equity Trust Payment Portal!</h1>  
              </div>)
             }
             {showPaymentTabs() && (
               <PaymentTabs setTimeoutTime={setTimeoutTime}/>
             )}
            </IonCol>
          </IonRow>
        </IonToolbar>
      </IonHeader>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userId: state.userId
  }
}

export default connect(mapStateToProps)(Header);
