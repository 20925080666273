import React from 'react';
import '../pages/Style.css';
import progressBarStepThree from '../images/step-three.svg';
import { 
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react';

interface ContainerProps { }

const Results: React.FC<ContainerProps> = () => {
  return (
    <div className="container">
      <IonGrid>
        <IonRow>
        <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
            <IonRow>
              <IonCol class="p-1 light-gr-bg">
                <IonRow>
                  <IonCol class="pl-3 pr-3 pt-1 pb-3 gr-border white-bg">
                    <IonRow class="divider">
                      <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                        <img src={progressBarStepThree} alt="progress bar" />
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        <h2 className="ion-text-left">Results</h2>

                        {/* Loop with this row */}
                        <IonRow class="mt-2 mb-2">
                          <IonCol class="light-gr-bg pt-1 pb-2 pl-3 pr-3" sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                            <h2><a href="/PaymentHome">// Add Results...</a></h2>
                          </IonCol>
                        </IonRow>
                        {/* End of row to loop */}

                        {/* Loop with this row */}
                        <IonRow class="mt-2 mb-2">
                          <IonCol class="light-gr-bg pt-1 pb-2 pl-3 pr-3" sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                            <h2><a href="/PaymentHome">// Add Results...</a></h2>
                          </IonCol>
                        </IonRow>
                        {/* End of row to loop */}

                        {/* Loop with this row */}
                        <IonRow class="mt-2 mb-2">
                          <IonCol class="light-gr-bg pt-1 pb-2 pl-3 pr-3" sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
                            <h2><a href="/PaymentHome">// Add Results...</a></h2>
                          </IonCol>
                        </IonRow>
                        {/* End of row to loop */}

                        <IonRow class="mt-5 divider" />
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default Results;
