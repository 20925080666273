import React from 'react';
import '../pages/Style.css';
import { 
  IonGrid,
  IonRow,
  IonCol,
  IonFooter
} from '@ionic/react';

const Footer: React.FC = () => {
  return (
    <div>
      <IonFooter class="footer">
        <IonGrid>
          <IonRow>
            <IonCol>
              <>
                <p>Contact Us For Help<br />
                  Phone - <a className="primary-color" href="tel:239-333-4864" target="_blank">239-333-4864</a><br/>
                  Email - <a className="primary-color" href="mailto:Accountingteam@midlandtrust.com" target="_blank">Accountingteam@midlandtrust.com</a>
                </p>
                <p>&copy;2024 Equity Trust Company | <a className="white-color" href="https://www.midlandtrust.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a> | <a className="white-color" href="https://www.midlandtrust.com/about-midland/" target="_blank" rel="noopener noreferrer">About</a></p>
              </>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonFooter>
    </div>
  );
};

export default Footer;
