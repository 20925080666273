import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Main from './pages/Main';
import ReactGA from 'react-ga'; 
import {isPlatform} from '@ionic/react'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import axios from 'axios';
if(isPlatform('android') || isPlatform('iphone') || isPlatform('ios')){
  axios.defaults.baseURL = process.env.REACT_APP_CUSTOM_DOMAIN || 'https://pay.midlandtrust.com'
  axios.defaults.withCredentials = true
}

const App: React.FC = () => {
  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'production' && process.env.REACT_APP_GA_TRACKING){
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING); 
    }
  }, []);

  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet animated={false}>
          <Route path="/:comp" render={() => <Main />} />
          <Route exact path="/" render={() => <Redirect to="/home" />} />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
}

export default App;
