import React, { useEffect, useState } from 'react';
import '../pages/Style.css';
import { 
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonSelect,
  IonSelectOption
} from '@ionic/react'; 
import { connect } from 'react-redux';
import calloutHelpers from '../helpers/CalloutHelpers'
import {setPageLoading} from '../store/actions/actions'

const PaymentHistory: React.FC<{setPageLoading(isLoading:boolean):any}> = ({setPageLoading}) => {
  const [selectedAsset, setSelectedAsset] = useState<string>('');
  const [paymentHistory, setPaymentHistory] = useState<assetDescriptionToTransactionList>();

  useEffect(()=>{
    setPageLoading(true)
    calloutHelpers.getPaymentHistory().then((result)=>{
      if(result.data){
        let resultPaymentHistory = result.data
        let assetDescriptionList = Object.keys(resultPaymentHistory)
        if(assetDescriptionList.length > 0){
          setPaymentHistory(resultPaymentHistory);
          setSelectedAsset(assetDescriptionList[0])
        }
      }
    }).finally(()=>{
      setPageLoading(false)
    })
  }, [])

  const generatePicklist = () =>{
    if(paymentHistory){
      let assetKeys = Object.keys(paymentHistory)
      if(assetKeys.length === 0){
        return ''
      }
      if(assetKeys.length === 1){
        return ` - ${selectedAsset}`
      }
      return (<IonItem>
        <IonSelect tabIndex={0} value={selectedAsset} interface='action-sheet' mode="ios" placeholder='Please Select an Asset' onIonChange={(event)=>{setSelectedAsset(event.detail.value);}}>
                {assetKeys?.map((value)=>{
                        return <IonSelectOption key={value} value={value}>{value}</IonSelectOption>
                })}
            </IonSelect>
        </IonItem>)
    }
  }

  return (
    <div className="container">
      <IonGrid>
        <IonRow>
          <IonCol class="p-1" sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
            <IonRow>
              <IonCol class="p-1 light-gr-bg">
                <IonRow>
                  <IonCol class="pl-3 pr-3 pt-3 pb-3 gr-border white-bg">
                  {paymentHistory && <h1>Payment History {generatePicklist()}</h1>}
                  {!paymentHistory && <h1>You have no history</h1>}
                    <p>Please allow up to two business days after the process date for these funds to be debited from your account.</p>
                   {paymentHistory && displayAssetHistory(selectedAsset, paymentHistory)}
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};




const displayAssetHistory = (assetName:string, paymentHistory?: assetDescriptionToTransactionList) =>{
  const formatDate = (rawDateString:string)=>{
    if(rawDateString.match(/\d{4}-\d{2}-\d{2}/)){
      return (new Date(rawDateString)).toLocaleDateString(undefined, {year:'numeric', month:'short', day:'numeric'})
    }else{
      return rawDateString
    }
  }
  if(!paymentHistory){
    return ''
  }
  let transactionList = paymentHistory[assetName];
  if(transactionList === undefined || transactionList?.length === 0){
    return <IonRow>
    No History for {assetName}
    </IonRow>
  }

  return (
    <IonRow>
      <IonCol sizeXs="6" sizeSm="6" sizeMd="6" sizeLg="6" sizeXl="6">
        <IonItem key={'Date'}>
          <p><b>Date</b></p>
        </IonItem>
        {transactionList.map((value)=>{
          return (
            <IonItem key={value.date}>
              <p>{formatDate(value.date)}</p>
            </IonItem>
          )
        })}
        </IonCol>
      <IonCol sizeXs="6" sizeSm="6" sizeMd="6" sizeLg="6" sizeXl="6">
        <IonItem key={'Amount'}>
          <p><b>Amount</b></p>
        </IonItem>
        {transactionList.map((value)=>{
          return (
            <IonItem key={value.date}>
              <p>${value.amount.toFixed(2)}</p>
            </IonItem>
          )
        })}
      </IonCol>
    </IonRow>
  )
}

const mapDispatchToProps = (dispatch:Function) => {
  return{
    setPageLoading: (isLoading:boolean)=>{dispatch(setPageLoading(isLoading))}
  }
}

export default connect(null, mapDispatchToProps)(PaymentHistory);
