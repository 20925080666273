import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import {IonGrid, IonCol, IonRow, IonInput, IonButton, IonLabel, IonText} from '@ionic/react';
import calloutHelpers from '../helpers/CalloutHelpers';
import {EMAIL_VALIDATION_PATTERN, PHONE_VALIDATION_PATTERN} from '../helpers/utils';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import * as actions from '../store/actions/actions';

const RegisterForm : React.FC<{userType: string, showLoadingSpinner: Function, setPhone: Function, setEmail: Function, setErrorMessage: Function, logoutForGuest: Function, setTimeoutTime: Function}> = ({userType, showLoadingSpinner, setPhone, setEmail, setErrorMessage, logoutForGuest, setTimeoutTime}) => {
    const location = useLocation();
    const history = useHistory();
    const { register, handleSubmit, setValue, errors } = useForm({
        mode: 'onChange',
    });
    const [authenticationMethod, setAuthenticationMethod] = useState<'email' | 'phone' | null>(null);

    let formRef = useRef<HTMLFormElement>(null); 

    useEffect(() => {
         if (location && location.state) {
             let locationState: any = location.state;
             setValue('email', locationState.email);
             setValue('phone', locationState.phone);
             setValue('name', locationState.name);
         }
    }, [location, setValue]);

    useEffect(() => {
        if (authenticationMethod) {
            dispatchSubmit();
        }
    }, [authenticationMethod, formRef]);

    useEffect(() => {
          if (Object.keys(errors).length > 0) {
            setAuthenticationMethod(null);
          }
    }, [errors]);

    useEffect(() => {
        const logOutOnUnload = (ev:any) => {
            return logoutAndRedirectHome();
        }
        if (userType === 'guest') {
            window.addEventListener('beforeunload', logOutOnUnload)
            let timeout = setTimeout(() => {
                logoutAndRedirectHome();
        }, 300000);

        return()=>{
            window.removeEventListener('beforeunload',logOutOnUnload);
            clearTimeout(timeout);
         }
        }
    }, [userType, logoutForGuest, history]);


    const submitAndAuthenticateByEmail = () => {
        setAuthenticationMethod('email');
    }

    const submitAndAuthenticateByPhone = () => {
        setAuthenticationMethod('phone');
    }

    const dispatchSubmit = () => {
        if (formRef && formRef.current) {
            formRef.current.dispatchEvent(new Event('submit', {cancelable:true}));
        }
    }
    
    const onSubmit = (data: any, event: any) => {
        event.preventDefault();
        if (!authenticationMethod) return; 

        let dupliateUserErrorMsg = 'This user is already registered. Please log in or change the email and/or phone number provided here.';

        if (userType === 'guest') {
            showLoadingSpinner(true);
            calloutHelpers.sendRegistrationCodeToGuestUser(data.name, data.email, data.phone?.replace(/[- )(]/g,''), authenticationMethod).then(result => {
                setEmail(data.email);
                setPhone(data.phone);
                showLoadingSpinner(false);
                history.push('/verifyregister');
              }).catch(err => {
                showLoadingSpinner(false);
                setErrorMessage(err.response?.data === 'Duplicate User' ?  dupliateUserErrorMsg : 'Error sending registration code.');
            }).finally(() => {
                setAuthenticationMethod(null);
            })
        } else if (userType === 'registering') {
            calloutHelpers.sendRegistrationCodeToRegisteringUser(data.name, data.email, data.phone?.replace(/[- )(]/g,''), authenticationMethod).then(result => {
                setPhone(data.phone);
                setEmail(data.email);
                showLoadingSpinner(false);
                history.push('/verifyregister');
            }).catch(err => {
                showLoadingSpinner(false);
                setErrorMessage(err.response?.data === 'Duplicate User' ?  dupliateUserErrorMsg : 'Error sending registration code.');
            }).finally(() => {
                setAuthenticationMethod(null);
            });
        }
    }

    const logoutAndRedirectHome = () => {
        calloutHelpers.signout().then(() => {
            logoutForGuest();
            history.push('/');
            setTimeoutTime(undefined);
        }).catch(err => {
            setErrorMessage('An error has occurred');
        })
    }

    return (
        <div className="container">
            <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
                <IonGrid>
                    <IonRow>
                        <IonCol className="p-1" sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
                            <IonRow>
                                <IonCol className="p-1 light-gr-bg">
                                    <IonRow>
                                        <IonCol className="pl-3 pr-3 pt-1 pb-3 gr-border white-bg">
                                            <h2 className='ion-text-left'> Register User </h2>
                                            {userType === 'registering' && (
                                                <p>
                                                    Recurring Payments can only be created by registered users. 
                                                </p>
                                            )}
                                            <p>Please enter the following information to register.</p>
                                        </IonCol>
                                    </IonRow> 
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="p-1 light-gr-bg">
                                    <IonRow>
                                        <IonCol className="pl-3 pr-3 pt-1 pb-3 gr-border white-bg">
                                            <IonRow>
                                                <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
                                                    <IonRow className="mt-2 mb-1">
                                                        <IonLabel>Phone Number *</IonLabel>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonInput className="ion-text-left gr-border" type="tel" name="phone" id="phone" ref={register(
                                                            {required: true, pattern: new RegExp(PHONE_VALIDATION_PATTERN)})} required={true}></IonInput>
                                                    </IonRow>
                                                    {errors.phone && <IonText color='danger'> Enter valid phone.</IonText>}
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <IonRow className="mt-2 mb-1">
                                                        <IonLabel>Email *</IonLabel>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonInput className="ion-text-left gr-border" type="email" name="email" id="email" maxlength={80} ref={register(
                                                            {required: true,
                                                            maxLength: 80,
                                                            pattern: new RegExp(EMAIL_VALIDATION_PATTERN)})} required={true}></IonInput>
                                                    </IonRow>
                                                    {errors.email && <IonText color='danger'> Enter valid email.</IonText>}
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <IonRow className="mt-2 mb-1">
                                                        <IonLabel>Name *</IonLabel>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonInput className="ion-text-left gr-border" name="name" maxlength={100} ref={register(
                                                        {required: true,
                                                        maxLength: 100, 
                                                        validate: (val) => 
                                                            val && (val.split(' ').length >= 2)
                                                        })} required={true}></IonInput>
                                                    </IonRow>
                                                    {errors.name && <IonText color='danger'> Enter First And Last Name. (Limit 100 chars)</IonText>}
                                                </IonCol>
                                            </IonRow>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonButton color="primary" className="ion-float-right" onClick={submitAndAuthenticateByPhone} size="large">Authenticate via Text</IonButton>
                                    <IonButton color="primary" className="ion-float-right" onClick={submitAndAuthenticateByEmail} size="large">Authenticate via Email</IonButton>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </form>
      </div>
    )
}

const mapStateToProps = (state : any) => {
    return {
        userType: state.userType
    }
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
      showLoadingSpinner : (isPageLoading: boolean) => dispatch(actions.setPageLoading(isPageLoading)), 
      logoutForGuest : () => dispatch(actions.logout())
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
