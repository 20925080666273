import React from 'react';
import '../pages/Style.css';
import { 
  IonRow,
  IonCol,
  IonLabel,
  IonTextarea,
} from '@ionic/react';


const AlternateLookup: React.FC<{register: any, onChange?: (event: CustomEvent) => void}> = ({register, onChange}) => {
  return (
    <div>
        <IonRow>
          <IonCol class="mt-4 mb-1">
            <h2 className="lined-text"><span>OR</span></h2>
          </IonCol>
        </IonRow>
        <IonRow class="mt-2 mb-1">
          <IonLabel>Briefly describe what you are paying(address, owners name, etc) [Maxlength = 250 chars] </IonLabel>
        </IonRow>
        <IonRow>
          <IonTextarea class="ion-text-left gr-border" name="altlookup" id="altlookup" onIonChange={onChange} required={true} ref={register} maxlength={250}></IonTextarea>
        </IonRow>
    </div>
  );
};

export default AlternateLookup;
