import React, {useRef, useEffect, useState} from 'react'; 
import {useHistory } from 'react-router-dom';
import { IonItem, IonInput, IonButton, IonGrid, IonCol, IonRow } from '@ionic/react';
import calloutHelper from '../helpers/CalloutHelpers'; 
import * as actions from '../store/actions/actions';
import { connect } from 'react-redux';

const OTPVerify: React.FC<{ phone: string | null, email: string | null, onLoggedIn: Function, userType: userTypes, onRegisterUserSuccess: Function, setTimeoutTime: Function, verifyType: 'login'|'register', setLoading: Function }> = ({ phone, email, onLoggedIn, userType, onRegisterUserSuccess, setTimeoutTime, verifyType, setLoading}) => {
    const [showError, setShowError] = useState(false);
    let history = useHistory(); 
    useEffect(() => {
      if (userType === 'loggedIn'){
        history.push('/paymenthome');
      }
    }, [userType, history])
    
    const otpTokenRef = useRef<HTMLIonInputElement>(null);

    const showTimedError = () => {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 3000);
    }
  
    const verifyOtp = () => {
      const otpToken = otpTokenRef.current!.value;
      if ((!otpToken) || (otpToken.toString().length !== 6)) {
        showTimedError();
        return;
      }

      let verifyOtpBody:verifyOtpBody = {
        phone,
        email,
        otpToken,
        verifyType
      }

      setLoading(true);
      calloutHelper.verifyOtpRequest(verifyOtpBody).then((data) => {
          if (data.data.expirationTime) {
            let expirationTime = Date.parse(data.data.expirationTime);
            let timeout: number = expirationTime - Date.now();
            setTimeoutTime(timeout);
          }

          if (userType === 'registering') {
            onRegisterUserSuccess(data.data.userId);
            history.push('/typeofpayment'); 
            return;
          }
          onLoggedIn(data.data.userId, data.data.assetsForPayer, data.data.queuedTransactions);
      }).catch(err => {
        console.log(err.message);
        showTimedError();
      }).finally(() => {
        setLoading(false);
      })
    }

    const resendOtp = () => {
      setLoading(true);
      calloutHelper.resendOtp(phone, email, verifyType, userType).then((res) => {
        return; 
      }).catch(err => {
        console.log(err.message);
        showTimedError();
      }).finally(() => {
        setLoading(false);
      })
    }

    return (
      <div className="container" style={{height:"75%"}}>
        <IonGrid>
            <IonRow>
            <IonCol class="p-2" sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
            <IonRow class={'otp-container'}>
              <IonCol class="p-2 light-gr-bg ">
                <IonRow>
                  <IonCol class="pl-2 pr-2 pt-1 pb-3 gr-border white-bg">
                    <IonRow class={'otp-input-center'}>
                      <IonInput  type='number' placeholder="000000" ref={otpTokenRef}/>
                    </IonRow>
                    <IonRow>
                      {showError && <IonItem class="mt-1" color="danger"><p className="white-color">Token Invalid</p></IonItem>}
                      <IonButton color='primary' onClick={verifyOtp} class={'otp-button-center'}>Verify Code</IonButton>
                    </IonRow>
                    <IonRow>
                      <IonButton color='secondary' fill='clear' onClick={resendOtp} className='otp-button-center'>Resend Code</IonButton>
                    </IonRow>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    )
}

const mapStateToProps = (state: any) => {
  return {
    userType: state.userType
  }
}

const mapDispatchToProps = (dispatch:Function) => {
  return {
    onLoggedIn: (userId: string, assets: Array<OtherAsset>, queuedTransactions: outstandingTransactions) => dispatch(actions.loginSuccess(userId, assets, queuedTransactions)),
    onRegisterUserSuccess: (userId: string) => dispatch(actions.registerUserSuccess(userId)),
    setLoading: (showLoadingSpinner : boolean) => dispatch(actions.setPageLoading(showLoadingSpinner))
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(OTPVerify); 